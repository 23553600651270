import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorModeValue,
  Select,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { Context as CompanyContext } from "@context/CompanyContext";

const ProspectCard = (props) => {
  const {
    state: { templates },
  } = useContext(CompanyContext);

  const {
    companyName,
    setCompanyName,
    contactName,
    setContactName,
    template,
    setTemplate,
  } = props;

  return (
    <Box
      as="form"
      bg="bg-surface"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      borderRadius="lg"
      w={props.w}
    >
      <Stack
        spacing="5"
        px={{
          base: "4",
          md: "6",
        }}
        py={{
          base: "5",
          md: "6",
        }}
      >
        <Stack
          spacing="6"
          direction={{
            base: "column",
            md: "row",
          }}
        >
          <FormControl id="companyName" isRequired={true}>
            <FormLabel>Company Name</FormLabel>
            <Input
              placeholder="Dunder Mifflin"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </FormControl>
          <FormControl id="contactName" isRequired={true}>
            <FormLabel>Company Contact Name</FormLabel>
            <Input
              placeholder="Michael Scott"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
            />
          </FormControl>
        </Stack>
        <FormControl id="templateSelect" isRequired={true}>
          <FormLabel>Template</FormLabel>
          <Select
            placeholder="Select one"
            value={template}
            onChange={(e) => {
              if (e.target.value == null || e.target.value == "") {
                setTemplate(undefined);
              } else {
                setTemplate(e.target.value);
              }
            }}
          >
            {templates.map((item) => {
              if (
                item.metadata?.status == null ||
                item.metadata?.status == "live"
              ) {
                return (
                  <option key={item._id} value={item._id}>
                    {item.templateName}
                  </option>
                );
              }
            })}
          </Select>
        </FormControl>
      </Stack>
    </Box>
  );
};

export default ProspectCard;
