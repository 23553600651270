import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toast } from "react-toastify";

const companyReducer = (state, action) => {
  switch (action.type) {
    case "fetch_documentation":
      return {
        ...state,
        company: action.payload.company,
        templates: action.payload.templates,
        plans: [...action.payload.plans].reverse(),
        ownerPlans: [...action.payload.plans]
          .filter((i) => `${i.owner._id}` == `${action.payload.userId}`)
          .reverse(),
      };
    case "fetch_plans":
      return {
        ...state,
        plans: [...action.payload.allPlans].reverse(),
        ownerPlans: [...action.payload.ownerPlans].reverse(),
        isPlansRefreshed: true,
      };
    case "create_plan":
      return {
        ...state,
        plans: [action.payload, ...state.plans],
        ownerPlans: [
          action.payload,
          ...state.plans.filter(
            (i) => `${i.owner._id}` == `${action.payload.owner._id}`
          ),
        ],
        lastCreated: action.payload,
      };
    case "delete_plan":
      return {
        ...state,
        plans: state.plans.filter((i) => `${i._id}` != `${action.payload}`),
        ownerPlans: state.ownerPlans.filter(
          (i) => `${i._id}` != `${action.payload}`
        ),
      };
    case "set_editing_plan":
      return {
        ...state,
        editingPlan: action.payload,
      };
    case "update_plan":
      return {
        ...state,
        plans: state.plans.map((i) => {
          if (`${i._id}` == `${action.payload._id}`) {
            return action.payload;
          } else {
            return i;
          }
        }),
        ownerPlans: state.ownerPlans.map((i) => {
          if (`${i._id}` == `${action.payload._id}`) {
            return action.payload;
          } else {
            return i;
          }
        }),
      };
    default:
      return state;
  }
};

const fetchDocumentation = (dispatch) => async () => {
  try {
    console.log("FETCHING COMPANY INFO");
    const response = await serverApi.get("/company-documentation");
    console.log(response.data);
    dispatch({ type: "fetch_documentation", payload: response.data });
  } catch (err) {
    console.log("fetchDocumentation error");
    console.log(err);
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const fetchPlans = (dispatch) => async () => {
  try {
    const response = await serverApi.get("/plans");
    console.log("FETCH PLANS: ");
    console.log(response.data);
    dispatch({ type: "fetch_plans", payload: response.data });
  } catch (err) {
    console.log("fetchPlans error");
    console.log(err);
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const createPlan =
  (dispatch) =>
  async ({
    template,
    prospectCompany,
    prospectName,
    prospectWhitelist,
    prospectContent,
    content,
    preload,
    metadata,
  }) => {
    try {
      console.log("Creating Plan");
      const response = await serverApi.post("/create-plan", {
        template,
        prospectCompany,
        prospectName,
        prospectWhitelist,
        prospectContent,
        content,
        preload,
        metadata,
      });
      dispatch({ type: "create_plan", payload: response.data });
      toast.success("Proposal created successfully");
    } catch (err) {
      console.log("createPlan error");
      console.log(err);
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const deletePlan =
  (dispatch) =>
  async ({ planId }) => {
    try {
      console.log("Deleting Plan");
      const response = await serverApi.post("/delete-plan", {
        planId,
      });
      dispatch({ type: "delete_plan", payload: planId });
      toast.success("Proposal deleted successfully");
    } catch (err) {
      console.log("deletePlan error");
      console.log(err);
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const setEditingPlan = (dispatch) => (plan) => {
  dispatch({ type: "set_editing_plan", payload: plan });
};

const editPlan =
  (dispatch) =>
  async ({
    planId,
    prospectCompany,
    prospectName,
    prospectWhitelist,
    prospectContent,
    content,
    preload,
    metadata,
  }) => {
    try {
      console.log("Editing Plan");
      const response = await serverApi.post("/edit-plan", {
        planId,
        prospectCompany,
        prospectName,
        prospectWhitelist,
        prospectContent,
        content,
        preload,
        metadata,
      });
      dispatch({ type: "update_plan", payload: response.data });
      toast.success("Link edited successfully");
    } catch (err) {
      console.log("editPlan error");
      console.log(err);
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

export const { Provider, Context } = createDataContext(
  companyReducer,
  {
    fetchDocumentation,
    fetchPlans,
    createPlan,
    deletePlan,
    setEditingPlan,
    editPlan,
  },
  {
    company: null,
    templates: [],
    plans: null,
    ownerPlans: null,
    isPlansRefreshed: false,
    lastCreated: null,
    editingPlan: null,
  }
);
