import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  StyleSheet,
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { Context as ConstructContext } from "@context/ConstructContext";
import ContentOverview from "./ContentOverview";
import {
  Box,
  Button,
  Stack,
  Text as CText,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  ButtonGroup,
  IconButton,
  Tooltip,
  Tag,
  Select,
} from "@chakra-ui/react";
import _ from "lodash";
import { translate } from "../../helpers/translateSchema";
import Form from "@rjsf/chakra-ui";

const StepDetails = ({ model, modelIndex }) => {
  const {
    modContentModel,
    setValid,
    state: { bankSchemas, bankLabels, successSchemas, content, navIndex },
  } = useContext(ConstructContext);
  const [currStep, setCurrStep] = useState(content[navIndex]);
  const [formSchema, setFormSchema] = useState(null);
  const [formData, setFormData] = useState(null);
  const [stepComponent, setStepComponent] = useState(null);
  const [successComponent, setSuccessComponent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  var Validator = require("jsonschema").Validator;
  var v = new Validator();

  useEffect(() => {
    setCurrStep(content[navIndex]);
    console.log(model?.component);
    console.log(stepComponent);
    if (!stepComponent || `${model.component}` != `${stepComponent}`) {
      setStepComponent(model.component);
    }
    if (
      !successComponent ||
      `${model?.successComponent}` != `${successComponent}`
    ) {
      setSuccessComponent(null);
      setSuccessComponent(model.successComponent);
    }
  }, [navIndex, content]);

  useEffect(() => {
    console.log("CHANGED NAV, UPDATING FORM DATA");
    setFormData(_.omitBy(model, _.isNil));
  }, [navIndex]);

  useEffect(() => {
    console.log("REFRESHING FORM");
    refreshForm();
    setFormData(_.omitBy(model, _.isNil));
  }, [stepComponent, successComponent]);

  useEffect(() => {
    onContentChange(formData);
  }, [formData]);

  const refreshForm = async () => {
    console.log("REFRESHING");
    let schemaForTranslate = _.cloneDeep(
      bankSchemas[`${model.component}`].schema
    );

    let schemaLabels = {
      ...bankLabels[`${model.component}`],
    };

    console.log(successSchemas);
    _.keys(successSchemas).forEach((successElem) => {
      console.log(successElem);
      schemaLabels = {
        ...schemaLabels,
        ..._.mapKeys(bankLabels[successElem], function (value, key) {
          return `response.successProps.${key}`;
        }),
      };
    });

    const translation = translate(schemaForTranslate, schemaLabels, {
      bankSchemas,
      bankLabels,
    });

    console.log(translation);
    setFormSchema(translation);
  };

  const onContentChange = (modelContent) => {
    console.log("FIRING ON CONTENT CHANGE");
    console.log(modelContent);
    if (model != null) {
      modContentModel({
        index: navIndex,
        value: {
          ...modelContent,
        },
        modelIndex: modelIndex != null ? modelIndex : null,
      });
    }
    if (formSchema && modelContent) {
      const isValid = v.validate(modelContent, formSchema.formSchema);
      console.log(isValid);
      setValid({ navIndex, isValid: isValid.errors.length == 0 });
    }
  };

  const debouncedSave = useCallback(
    _.debounce(async (data) => {
      setFormData(data);
    }, 500),
    []
  );

  const log = (type) => console.log.bind(console, type);

  return (
    <Box
      bg="bg-surface"
      px={{
        base: "8",
        md: "12",
      }}
      py="10"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      borderTopWidth="4px"
      borderColor="accent"
    >
      <Stack spacing="1">
        {!formSchema || isLoading ? (
          <ActivityIndicator />
        ) : (
          <Form
            schema={formSchema.formSchema}
            formData={formData}
            uiSchema={formSchema.uiSchema}
            onChange={(e) => {
              debouncedSave(e.formData);
            }}
            onSubmit={log("submitted")}
            onError={log("errors")}
            liveValidate={false}
          />
        )}
      </Stack>
    </Box>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: 30,
    fontWeight: "500",
    marginBottom: 20,
  },
});

export default StepDetails;
