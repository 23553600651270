import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { Context as CompanyContext } from "@context/CompanyContext";

const DeleteModal = ({ plan, isOpen, onClose }) => {
  const {
    deletePlan,
    state: { company },
  } = useContext(CompanyContext);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(null)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Link</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color="muted" fontSize="sm">
            This will permanently remove the unique link at https://
            {company?.domain}.coast.rest/{plan?._id}. Nobody will be able to
            access this instance again.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={"purple"}
            mr={3}
            onClick={async () => {
              setIsLoading(true);
              await deletePlan({ planId: plan._id });
              setIsLoading(false);
              onClose(null);
            }}
            isLoading={isLoading}
          >
            Confirm Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
