import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, View, Text, Image, ActivityIndicator } from "react-native";
import {
  Box,
  Button,
  Stack,
  StackDivider,
  Text as CText,
  Divider,
  HStack,
  useColorModeValue,
  Wrap,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Context as CompanyContext } from "@context/CompanyContext";
import StepCard from "./StepCard";
import ProspectCard from "./ProspectCard";
import PreloadCard from "./PreloadCard";
import { motion } from "framer-motion";
import * as RootNavigation from "../../RootNavigation";
import { toast } from "react-toastify";

const parseGoals = (content) => {
  const goalList = [];
  for (let i = 0; i < content.length; i++) {
    const currStep = content[i];
    if (currStep.goal != null) {
      if (!goalList.includes(`${currStep.goal.title}`))
        goalList.push(`${currStep.goal.title}`);
    }
  }

  return goalList;
};

const CreateScreen = () => {
  const {
    createPlan,
    state: { company, lastCreated, templates },
  } = useContext(CompanyContext);
  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [template, setTemplate] = useState(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [contentList, setContentList] = useState(null);
  const [goalList, setGoalList] = useState(null);
  const [preload, setPreload] = useState(null);
  const [whitelist, setWhitelist] = useState(null);
  const [prospectContent, setProspectContent] = useState(null);
  const [isTeamEditing, setIsTeamEditing] = useState(false);
  const tagColor = useColorModeValue("gray.600", "gray.300");
  const shadowColor = useColorModeValue("sm", "sm-dark");
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (template != null) {
      console.log(template);
      const selectedTemplate = templates.find(
        (temp) => `${temp._id}` == `${template}`
      );
      setSelectedTemplate(selectedTemplate);
      setContentList(selectedTemplate.content);
      setGoalList(parseGoals(selectedTemplate.content));
      console.log(selectedTemplate.preload);
      if (selectedTemplate.preload && selectedTemplate.preload.length > 0) {
        setPreload([
          ...selectedTemplate.preload.map((i) => {
            return {
              fieldKey: i.fieldKey,
              componentType: i.componentType,
              value: i.value ? i.value : "",
            };
          }),
        ]);
      } else {
        setPreload(null);
      }
      if (company.metadata?.defaultEditingPermission != null) {
        if (company.metadata?.defaultEditingPermission == "team")
          setIsTeamEditing(true);
      }
    } else {
      setContentList(null);
      setGoalList(null);
      setPreload(null);
      setSelectedTemplate(null);
    }
  }, [template]);

  useEffect(() => {
    if (isLoading) {
      setTemplate(undefined);
      setIsLoading(false);
      onOpen();
      setContentList(null);
      setGoalList(null);
      setPreload(null);
      setSelectedTemplate(null);
    }
  }, [lastCreated]);

  const validateInputs = () => {
    if (companyName == "" || contactName == "") {
      toast.error("Company Name and Contact Name must be completed");
      return false;
    } else if (!template) {
      toast.error("Proposal template must be selected");
      return false;
    }
    return true;
  };

  const onCreate = async () => {
    if (isLoading == true) return;
    const isValidated = validateInputs();
    if (!isValidated) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    await createPlan({
      template: template,
      prospectCompany: companyName,
      prospectName: contactName,
      prospectWhitelist:
        whitelist != null && whitelist.prospectWhitelist?.length > 0
          ? whitelist.prospectWhitelist
          : undefined,
      prospectContent:
        prospectContent != null ? prospectContent.prospectContent : undefined,
      content: contentList,
      preload,
      metadata: {
        editingPermission: isTeamEditing ? "team" : "personal",
      },
    });
  };

  const handleContentChange = (e, index, field) => {
    const { name, value } = e.target;
    const list = [...contentList];
    list[index][field] = value;
    setContentList(list);
  };

  const handleGoalChange = (e, index) => {
    const { value } = e.target;
    const oldGoalTitle = goalList[index];

    const list = [...contentList];
    list.forEach((i) => {
      if (i.goal != null && `${i.goal.title}` == `${oldGoalTitle}`)
        i.goal.title = value;
    });

    const newGoalList = [...goalList];
    newGoalList[index] = value;

    setGoalList(newGoalList);
    setContentList(list);
  };

  const handlePreloadChange = (e, index) => {
    const { name, value } = e.target;
    const preloadData = [...preload];
    preloadData[index].value = value;
    setPreload(preloadData);
  };

  const re = /\{\{.*?}}/g;
  const findVars = (str) => {
    const matches = str.match(re);
    if (matches == null) {
      return [];
    } else {
      return matches;
    }
  };

  return (
    <View
      style={{
        paddingTop: 40,
        paddingHorizontal: 40,
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <Text style={styles.header}>Create new proposal</Text>

      <Box
        py={{
          base: "4",
          md: "8",
        }}
      >
        <Stack
          direction={{
            base: "column",
            lg: "row",
          }}
          spacing={{
            base: "5",
            lg: "8",
          }}
          justify="space-between"
        >
          <Box flexShrink={0} w="xs">
            <CText fontSize="lg" fontWeight="medium">
              Nuts & Bolts
            </CText>
            <View style={{ marginVertical: 5 }} />
            <CText color="muted" fontSize="sm">
              Basic info about the prospect you're pitching
            </CText>
          </Box>
          <ProspectCard
            w={{
              lg: "3xl",
            }}
            companyName={companyName}
            setCompanyName={setCompanyName}
            contactName={contactName}
            setContactName={setContactName}
            template={template}
            setTemplate={setTemplate}
          />
        </Stack>

        <View style={{ marginVertical: 30 }} />

        {preload != null ? (
          <View style={{ marginBottom: 30 }}>
            <HStack>
              <Divider />
              <CText fontSize="lg" fontWeight="medium" whiteSpace="nowrap">
                Template Preload Items
              </CText>
              <Divider />
            </HStack>
            <View style={{ marginVertical: 30 }} />
            <Stack
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: "5",
                lg: "8",
              }}
              justify="space-between"
            >
              <Box flexShrink={0} w="xs">
                <View>
                  <CText fontSize="lg" fontWeight="medium">
                    What are Preload Items?
                  </CText>
                  <View style={{ marginVertical: 5 }} />
                  <CText color="muted" fontSize="sm">
                    These are custom options tied to the template selected to
                    further personalize every instance.
                  </CText>
                </View>
              </Box>

              <PreloadCard
                data={preload}
                schema={selectedTemplate?.preload}
                handlePreloadChange={handlePreloadChange}
                w={{
                  lg: "3xl",
                }}
                whitelist={whitelist}
                setWhitelist={setWhitelist}
                prospectContent={prospectContent}
                setProspectContent={setProspectContent}
                isTeamEditing={isTeamEditing}
                setIsTeamEditing={setIsTeamEditing}
              />
            </Stack>
          </View>
        ) : null}

        {contentList != null ? (
          <View>
            <HStack>
              <Divider />
              <CText fontSize="lg" fontWeight="medium" whiteSpace="nowrap">
                Proposal Content
              </CText>
              <Divider />
            </HStack>
            <View style={{ marginVertical: 30 }} />
            <Stack spacing="5" divider={<StackDivider />}>
              {/* GOAL CHANGING */}
              <Stack
                direction={{
                  base: "column",
                  lg: "row",
                }}
                spacing={{
                  base: "5",
                  lg: "8",
                }}
                justify="space-between"
              >
                <Box flexShrink={0} w="xs">
                  <View>
                    <CText fontSize="lg" fontWeight="medium">
                      Goals
                    </CText>
                    <View style={{ marginVertical: 5 }} />
                    <CText color="muted" fontSize="sm">
                      The steps below are already grouped into goals. You can
                      change the name of the goals below, and scroll down to see
                      which steps are in grouped in each goal.
                    </CText>
                  </View>
                </Box>
                <Box
                  as="form"
                  bg="bg-surface"
                  boxShadow={shadowColor}
                  borderRadius="lg"
                  w={{ lg: "3xl" }}
                >
                  <Stack
                    spacing="5"
                    px={{
                      base: "4",
                      md: "6",
                    }}
                    py={{
                      base: "5",
                      md: "6",
                    }}
                  >
                    {goalList.map((item, index) => {
                      return (
                        <FormControl id={item} key={`${index}`}>
                          <FormLabel>Goal No. {index + 1}</FormLabel>

                          <Input
                            value={item}
                            onChange={(e) => handleGoalChange(e, index)}
                          />
                        </FormControl>
                      );
                    })}
                  </Stack>
                </Box>
              </Stack>

              {contentList.map((item, index) => {
                const matchedVars = findVars(item.title + " " + item.body);

                return (
                  <Stack
                    key={`${item._id}`}
                    direction={{
                      base: "column",
                      lg: "row",
                    }}
                    spacing={{
                      base: "5",
                      lg: "8",
                    }}
                    justify="space-between"
                  >
                    <Box flexShrink={0} w="xs">
                      {item.type == "overview" ? (
                        <View>
                          <CText fontSize="lg" fontWeight="medium">
                            Overview
                          </CText>
                          <View style={{ marginVertical: 5 }} />
                          <CText color="muted" fontSize="sm">
                            This is the cover letter that is shown on the first
                            tab. This is typically a good place to write a quick
                            summary of what you about the customer and how you
                            can help.
                          </CText>
                        </View>
                      ) : (
                        <View>
                          <CText fontSize="lg" fontWeight="medium">
                            Step No. {item.goal.stepNumber + 1}
                          </CText>
                          <CText fontSize="lg" fontWeight="medium">
                            Goal: {item.goal.title}
                          </CText>
                          <View style={{ marginVertical: 5 }} />
                          <CText color="muted" fontSize="sm">
                            Component type: {item.model.component}
                          </CText>
                          <View style={{ marginVertical: 10 }} />
                          {matchedVars.length > 0 ? (
                            <View
                              style={{
                                backgroundColor: "white",
                                padding: 10,
                                borderRadius: 10,
                              }}
                            >
                              <CText fontSize="md" fontWeight="medium">
                                Dynamic Variables
                              </CText>
                              <Wrap shouldWrapChildren mt="5" color={tagColor}>
                                {matchedVars.map((tag) => (
                                  <Tag key={tag} color="inherit" px="3">
                                    {tag}
                                  </Tag>
                                ))}
                              </Wrap>
                            </View>
                          ) : null}
                        </View>
                      )}
                    </Box>
                    <StepCard
                      item={item}
                      index={index}
                      contentList={contentList}
                      handleContentChange={handleContentChange}
                      w={{
                        lg: "3xl",
                      }}
                    />
                  </Stack>
                );
              })}
            </Stack>
            <View style={{ marginTop: 50, width: "100%" }}>
              <motion.div whileHover={{ scale: 1.02 }}>
                <Button
                  variant="primary"
                  bg="#4f41f1"
                  onClick={() => onCreate()}
                  isLoading={isLoading}
                  w="100%"
                >
                  Submit Proposal
                </Button>
              </motion.div>
            </View>
          </View>
        ) : null}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Proposal created!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CText color="muted" fontSize="sm">
              Easy, right? Your plan was created successfully at https://
              {company && company.domain ? company.domain : null}.coast.rest/
              {lastCreated ? lastCreated._id : null}
            </CText>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme={"purple"}
              mr={3}
              onClick={() =>
                window.open(
                  `https://${
                    company && company.domain ? company.domain : null
                  }.coast.rest/${lastCreated ? lastCreated._id : null}`,
                  "_blank"
                )
              }
            >
              Check it out
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: 30,
    fontWeight: "500",
  },
});

export default CreateScreen;
