import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toast } from "react-toastify";
import { arrayMoveImmutable } from "array-move";

const constructReducer = (state, action) => {
  switch (action.type) {
    case "fetch_schemas":
      return {
        ...state,
        bankSchemas: action.payload.schemas.allSchemas,
        bankLabels: action.payload.labels,
        stepSchemas: action.payload.schemas.stepSchemas,
        successSchemas: action.payload.schemas.successSchemas,
      };
    case "create_template":
      return {
        ...state,
        lastCreated: action.payload,
      };
    case "navigate_content":
      return {
        ...state,
        navIndex: action.payload,
      };
    case "add_content":
      return {
        ...state,
        navIndex: state.content.length,
        content: [...state.content, action.payload],
        stepFlag: state.stepFlag + 1,
      };
    case "mod_content":
      return {
        ...state,
        content: state.content.map((item, index) => {
          if (index == action.payload.index) {
            return { ...item, ...action.payload.value };
          } else {
            return item;
          }
        }),
      };
    case "mod_content_model":
      return {
        ...state,
        content: state.content.map((item, index) => {
          if (index == action.payload.index) {
            return {
              ...item,
              model: { ...item.model, ...action.payload.value },
            };
          } else {
            return item;
          }
        }),
      };
    case "mod_content_model_index":
      return {
        ...state,
        content: state.content.map((item, index) => {
          if (index == action.payload.index) {
            return {
              ...item,
              models: item.models.map((singleModel, i) => {
                if (i == action.payload.modelIndex) {
                  return {
                    ...singleModel,
                    ...action.payload.value,
                  };
                } else {
                  return singleModel;
                }
              }),
            };
          } else {
            return item;
          }
        }),
      };
    case "mod_preload":
      return {
        ...state,
        preload: action.payload,
      };
    case "mod_workers":
      return {
        ...state,
        workers: action.payload,
      };
    case "replace_content":
      return {
        ...state,
        content: action.payload,
        stepFlag: state.stepFlag + 1,
      };
    case "move_step":
      return {
        ...state,
        content: arrayMoveImmutable(
          state.content,
          action.payload.fromIndex,
          action.payload.toIndex
        ),

        stepFlag: state.stepFlag + 1,
      };
    case "set_goal_list":
      return {
        ...state,
        goalList: action.payload,
      };
    case "update_step_numbers":
      return {
        ...state,
        content: action.payload,
      };
    case "set_template_name":
      return {
        ...state,
        templateName: action.payload,
      };
    case "set_editing_mode":
      return {
        ...state,
        editingMode: action.payload,
      };
    case "set_updating_template":
      return {
        ...state,
        updatingTemplate: action.payload,
      };
    default:
      return state;
  }
};

const fetchSchemas = (dispatch) => async () => {
  try {
    const response = await serverApi.get("/bank-schemas");
    console.log(response.data);
    dispatch({ type: "fetch_schemas", payload: response.data });
  } catch (err) {
    console.log("fetchSchemas error");
    console.log(err);
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const createTemplate = (dispatch, state) => async () => {
  try {
    let validated = true;
    state.goalList.forEach((goal) => {
      goal.steps.forEach((step) => {
        if (step.isValid == false) {
          validated = false;
        }
      });
    });
    //TODO Remove
    if (validated == true || true) {
      if (state.editingMode == "create") {
        const response = await serverApi.post("/create-template", {
          templateName: state.templateName,
          content: state.content,
          preload: state.preload,
          workers: state.workers,
        });
        dispatch({ type: "create_template", payload: response.data });
        toast.success("Congrats! Template created successfully");
      } else if (state.editingMode == "update") {
        const response = await serverApi.post("/update-template", {
          templateSchema: {
            ...state.updatingTemplate,
            templateName: state.templateName,
            content: state.content,
            preload: state.preload,
            workers: state.workers,
          },
        });
        dispatch({ type: "create_template", payload: response.data });
        toast.success("Congrats! Template updated successfully");
      }
    } else {
    }
  } catch (err) {
    console.log("createTemplate error");
    console.log(err);
    toast.error("Template not validated");
  }
};

const convertSchemaToObj = (arr) => {
  console.log(arr);
  const val = {};
  arr.forEach((i) => {
    val[i.schemaTitle] = _.omit(i, ["schemaTitle"]);
  });
  return val;
};

const nullTest = (val) => {
  return Object.keys(val).reduce(
    (acc, key) => (
      (acc[key] = val[key] === Object(val[key]) ? nullTest(val[key]) : null),
      acc
    ),
    {}
  );
};

const navigateContent =
  (dispatch) =>
  async ({ index }) => {
    dispatch({ type: "navigate_content", payload: index });
  };

const addContent = (dispatch, state) => async () => {
  console.log(state.goalList);
  dispatch({
    type: "add_content",
    payload: {
      type: "step",
      title: `Untitled Step (${state.content.length})`,
      body: undefined,
      dbKey: undefined,
      goal: {
        title: `${
          state.goalList[state.goalList.length - 1]
            ? state.goalList[state.goalList.length - 1].title
            : "Untitled Goal"
        }`,
        stepNumber: state.goalList[state.goalList.length - 1]
          ? state.goalList[state.goalList.length - 1].steps.length
          : 0,
      },
    },
  });
};

const initializeContent =
  (dispatch, state) =>
  async ({ index, componentName, modelIndex }) => {
    console.log("NEW COMPONENT: ");
    console.log(componentName);
    console.log("MODEL INDEX: " + modelIndex);
    dispatch({
      type: "mod_content",
      payload: { index, value: {} },
    });
    const componentSchema = state.bankSchemas[`${componentName}`]?.schema;
    if (componentSchema != null) {
      const currentData = state.content[index];
      const shell = {};
      componentSchema.forEach((i) => {
        if (
          i.type == "String" ||
          i.type == "Number" ||
          i.type == "Boolean" ||
          i.type == "Object"
        ) {
          shell[i.schemaTitle] = null;
        } else if (`${i.type.substring(0, 5)}` == "Array") {
          shell[i.schemaTitle] = [];
        }
      });

      console.log(currentData);
      let modelOutline = {
        ...shell,
        title: currentData.title,
        body: currentData.body,
        dbKey: currentData.dbKey,
        view: "default",
        component: componentName,
      };

      let moddedContent;
      if (modelIndex != null && state.content[index].models != null) {
        console.log("EXECUTING HERE");
        const newModels = _.cloneDeep(state.content[index].models);
        newModels[modelIndex] = modelOutline;

        moddedContent = {
          ...currentData,
          models: newModels,
        };
        dispatch({
          type: "mod_content",
          payload: { index, value: moddedContent },
        });
      } else if (modelIndex != null && !state.content[index].models) {
        console.log("EXECUTING HERE 2");
        moddedContent = {
          ...currentData,
          models: [{ ...state.content[index].model }, modelOutline],
          model: undefined,
        };
        dispatch({
          type: "mod_content",
          payload: { index, value: moddedContent },
        });
      } else {
        console.log("EXECUTING HERE 3");
        moddedContent = {
          ...currentData,
          model: modelOutline,
        };
        dispatch({
          type: "mod_content",
          payload: { index, value: moddedContent },
        });
      }
    } else {
      const currentData = state.content[index];
      const moddedContent = {
        ...currentData,
        model: null,
      };
      dispatch({
        type: "mod_content",
        payload: { index, value: moddedContent },
      });
    }
  };

const modContent =
  (dispatch) =>
  async ({ index, value }) => {
    dispatch({ type: "mod_content", payload: { index, value } });
  };

const modContentModel =
  (dispatch) =>
  async ({ index, value, modelIndex }) => {
    if (modelIndex != null) {
      dispatch({
        type: "mod_content_model_index",
        payload: { index, value, modelIndex },
      });
    } else {
      dispatch({ type: "mod_content_model", payload: { index, value } });
    }
  };

const modPreload = (dispatch) => async (preload) => {
  dispatch({ type: "mod_preload", payload: preload });
};
const modWorkers = (dispatch) => async (workers) => {
  dispatch({ type: "mod_workers", payload: workers });
};

const removeContent =
  (dispatch, state) =>
  async ({ index }) => {
    let newContent;
    if (index > -1) {
      newContent = [...state.content];
      newContent.splice(index, 1);
    } else {
      newContent = state.content;
    }

    console.log(newContent);

    dispatch({ type: "replace_content", payload: newContent });
  };

const moveStep =
  (dispatch, state) =>
  async ({ fromIndex, toIndex }) => {
    dispatch({ type: "move_step", payload: { fromIndex, toIndex } });
  };

const setGoalList =
  (dispatch) =>
  async ({ lst }) => {
    dispatch({ type: "set_goal_list", payload: lst });
  };

const setValid =
  (dispatch, state) =>
  async ({ navIndex, isValid }) => {
    const goalListClone = [...state.goalList];
    goalListClone.forEach((goal) => {
      if (`${goal.title}` == `${state.content[navIndex].goal.title}`) {
        goal.steps.forEach((step) => {
          if (step.navIndex == navIndex) {
            step.isValid = isValid;
          }
        });
      }
    });
    dispatch({ type: "set_goal_list", payload: goalListClone });
  };

const updateStepNumbers = (dispatch, state) => async () => {
  console.log("updating step numbers");
  let changedFlag = false;
  let updatedContent = [...state.content];
  state.goalList.forEach((goalOb) => {
    goalOb.steps.forEach((step, goalIndex) => {
      if (updatedContent[step.navIndex].goal.stepNumber != goalIndex) {
        console.log("NEEDED UPDATE");
        updatedContent[step.navIndex].goal.stepNumber = goalIndex;
        changedFlag = true;
      }
    });
  });

  console.log("UPDATED CONTENT:");
  console.log(updatedContent);

  if (changedFlag == true)
    dispatch({ type: "update_step_numbers", payload: updatedContent });
};

const setTemplateName = (dispatch) => async (newName) => {
  dispatch({ type: "set_template_name", payload: newName });
};

function filterObject(obj, keys) {
  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] == "object") {
      filterObject(obj[i], keys);
    } else if (keys.includes(`${i}`)) {
      delete obj[i];
    }
  }
  return obj;
}

const setExistingTemplate = (dispatch) => async (template) => {
  console.log(template);
  dispatch({ type: "navigate_content", payload: -1 });

  // Remove all _id fields from existing template to avoid mongodb errors
  let contentClone = _.cloneDeep(template.content);
  contentClone = filterObject(contentClone, [
    "_id",
    "__v",
    "updatedAt",
    "createdAt",
  ]);
  console.log(contentClone);

  dispatch({ type: "replace_content", payload: contentClone });

  if (template.preload != null) {
    let preloadClone = _.cloneDeep(template.preload);
    preloadClone = filterObject(preloadClone, [
      "_id",
      "__v",
      "updatedAt",
      "createdAt",
    ]);
    dispatch({ type: "mod_preload", payload: { preload: [...preloadClone] } });
  }

  if (template.workers != null) {
    let workersClone = _.cloneDeep(template.workers);
    workersClone = filterObject(workersClone, [
      "_id",
      "__v",
      "updatedAt",
      "createdAt",
    ]);
    dispatch({ type: "mod_workers", payload: { ...workersClone } });
  }

  dispatch({
    type: "set_editing_mode",
    payload: "create",
  });
};

const setUpdateTemplate = (dispatch) => async (template) => {
  console.log(template);
  dispatch({ type: "navigate_content", payload: -1 });

  let contentClone = _.cloneDeep(template.content);
  contentClone = filterObject(contentClone, ["__v", "updatedAt", "createdAt"]);
  dispatch({ type: "replace_content", payload: contentClone });

  if (template.preload != null) {
    let preloadClone = _.cloneDeep(template.preload);
    preloadClone = filterObject(preloadClone, [
      "__v",
      "updatedAt",
      "createdAt",
    ]);
    dispatch({ type: "mod_preload", payload: { preload: [...preloadClone] } });
  }

  if (template.workers != null) {
    let workersClone = _.cloneDeep(template.workers);
    workersClone = filterObject(workersClone, [
      "__v",
      "updatedAt",
      "createdAt",
    ]);
    dispatch({ type: "mod_workers", payload: { ...workersClone } });
  }

  dispatch({ type: "set_updating_template", payload: _.cloneDeep(template) });
  dispatch({
    type: "set_template_name",
    payload: template.templateName,
  });
  dispatch({ type: "set_editing_mode", payload: "update" });
};

const clearTemplate = (dispatch) => async (template) => {
  dispatch({
    type: "replace_content",
    payload: [
      {
        type: "overview",
      },
      {
        type: "step",
        title: "Untitled Step",
        body: undefined,
        dbKey: undefined,
        goal: {
          title: "Untitled Goal",
          stepNumber: 0,
        },
      },
    ],
  });

  dispatch({
    type: "mod_preload",
    payload: null,
  });

  dispatch({
    type: "mod_workers",
    payload: null,
  });

  dispatch({
    type: "set_template_name",
    payload: "",
  });

  dispatch({
    type: "set_editing_mode",
    payload: "create",
  });
};

export const { Provider, Context } = createDataContext(
  constructReducer,
  {
    fetchSchemas,
    createTemplate,
    navigateContent,
    addContent,
    initializeContent,
    modContent,
    modContentModel,
    modPreload,
    modWorkers,
    removeContent,
    moveStep,
    setGoalList,
    setValid,
    updateStepNumbers,
    setTemplateName,
    setExistingTemplate,
    setUpdateTemplate,
    clearTemplate,
  },
  {
    bankSchemas: null,
    bankLabels: null,
    stepSchemas: null,
    successSchemas: null,
    templateName: "",
    lastCreated: null,
    goalList: [],
    stepFlag: 0,

    // User Controlled State
    navIndex: 0,
    editingMode: "create", // one of "create", "update"
    updatingTemplate: null,
    content: [
      {
        type: "overview",
      },
      {
        type: "step",
        title: "Untitled Step",
        body: undefined,
        dbKey: undefined,
        goal: {
          title: "Untitled Goal",
          stepNumber: 0,
        },
      },
    ],
    preload: null,
    workers: null,
  }
);
