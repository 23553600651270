import AsyncStorage from "@react-native-async-storage/async-storage";
import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import axios from "axios";
import { toast } from "react-toastify";
import posthog from "posthog-js";

const authReducer = (state, action) => {
  switch (action.type) {
    case "add_error":
      return { ...state, errorMessage: action.payload };
    case "login":
      return { errorMessage: "", token: action.payload };
    case "clear_error_message":
      return { ...state, errorMessage: "" };
    case "logout":
      return { ...state, token: null, errorMessage: "" };
    case "fetch_company":
      return { ...state, company: action.payload };
    default:
      return state;
  }
};

const tryLocalLogin = (dispatch) => async () => {
  const token = await AsyncStorage.getItem("token");
  if (token) {
    dispatch({ type: "login", payload: token });
  }
};

const clearErrorMessage = (dispatch) => () => {
  dispatch({ type: "clear_error_message" });
};

const signup = (dispatch) => {
  return async ({
    firstName,
    lastName,
    email,
    password,
    companyId,
    companyKey,
  }) => {
    try {
      const response = await serverApi.post("/signup", {
        firstName,
        lastName,
        email,
        password,
        companyId,
        companyKey,
      });

      if (
        response.data != null &&
        response.data.error != null &&
        response.data.error != ""
      ) {
        toast.error(response.data.error);
        dispatch({
          type: "add_error",
          payload: response.data.error,
        });
        return;
      }

      await AsyncStorage.setItem("token", response.data.token);
      await AsyncStorage.setItem("email", email);
      dispatch({
        type: "login",
        payload: response.data.token,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "add_error",
        payload: "Something went wrong with sign up",
      });
    }
  };
};

const login = (dispatch) => {
  return async ({ email, password }) => {
    try {
      const response = await serverApi.post("/login", { email, password });
      await AsyncStorage.setItem("token", response.data.token);
      await AsyncStorage.setItem("email", email);

      console.log("SIGN IN TOKEN: " + response.data.token);
      dispatch({
        type: "login",
        payload: response.data.token,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "add_error",
        payload: "Something went wrong with sign in",
      });
      toast.error("Your email or password is incorrect.");
    }
  };
};

//MANUALLY DISCONNECT SOCKET
const logout = (dispatch) => {
  return async () => {
    posthog.reset();
    await AsyncStorage.removeItem("token");
    await AsyncStorage.removeItem("email");
    await AsyncStorage.removeItem("userId");
    await AsyncStorage.removeItem("userInfo");
    dispatch({ type: "logout" });
  };
};

const fetchCompany = (dispatch) => {
  return async ({ companyId }) => {
    try {
      const response = await serverApi.post("/company", { companyId });
      dispatch({
        type: "fetch_company",
        payload: response.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const { Provider, Context } = createDataContext(
  authReducer,
  //All Actions below
  {
    tryLocalLogin,
    clearErrorMessage,
    signup,
    login,
    logout,
    fetchCompany,
  },
  {
    token: null,
    errorMessage: "",
    company: null,
  }
);
