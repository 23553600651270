import { Box, HStack } from "@chakra-ui/react";
import * as React from "react";
import { BsCaretRightFill } from "react-icons/bs";

export const NavItem = (props) => {
  const { active, subtle, icon, children, label, endElement, href, onClick } =
    props;
  return (
    <HStack
      as="a"
      href={href}
      w="full"
      px="3"
      py="2"
      cursor="pointer"
      userSelect="none"
      rounded="md"
      transition="all 0.2s"
      bg={active ? "gray.200" : undefined}
      _hover={{
        bg: "gray.100",
      }}
      _active={{
        bg: "gray.400",
      }}
      onClick={onClick}
    >
      <Box fontSize="lg" color={"gray.400"}>
        {icon}
      </Box>
      <Box flex="1" fontWeight="inherit">
        {label}
      </Box>
      {endElement && !children && <Box>{endElement}</Box>}
      {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
    </HStack>
  );
};
