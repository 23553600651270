import { StyleSheet, Text, View, ActivityIndicator } from "react-native";
import { Box, Flex, HStack, Img, useMenuButton } from "@chakra-ui/react";
import React, { useContext } from "react";
import { Context as UserContext } from "@context/UserContext";
import { Context as CompanyContext } from "@context/CompanyContext";
import { HiSelector } from "react-icons/hi";

export const AccountSwitcherButton = (props) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const {
    state: { company },
  } = useContext(CompanyContext);
  const buttonProps = useMenuButton(props);

  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      bg="gray.100"
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{
        bg: "gray.300",
      }}
      _focus={{
        shadow: "outline",
      }}
    >
      <HStack flex="1" spacing="3">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgColor="#4f41f1"
          w="8"
          h="8"
          rounded="md"
        >
          <Text style={{ fontSize: 14, color: "white" }}>
            {userInfo.firstName.charAt(0)}
            {userInfo.lastName.charAt(0)}
          </Text>
        </Box>
        <Box textAlign="start">
          <Box noOfLines={1} fontWeight="semibold">
            {userInfo.firstName} {userInfo.lastName}
          </Box>

          <Box fontSize="xs" color="gray.500">
            {company != null ? company.name : "Fetching..."}
          </Box>
        </Box>
      </HStack>
      <Box fontSize="lg" color="gray.400">
        <HiSelector />
      </Box>
    </Flex>
  );
};
