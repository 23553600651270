import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { Context as ConstructContext } from "@context/ConstructContext";
import ContentOverview from "./ContentOverview";
import {
  Box,
  Button,
  Stack,
  Text as CText,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Tooltip,
  IconButton,
  Tag,
  Select,
} from "@chakra-ui/react";
import { CreatableSelect } from "chakra-react-select";
import _ from "lodash";
import Form from "@rjsf/chakra-ui";

const StepDetails = () => {
  const {
    updateStepNumbers,
    navigateContent,
    modContent,
    modContentModel,
    initializeContent,
    moveStep,
    state: { bankSchemas, stepSchemas, content, navIndex, goalList },
  } = useContext(ConstructContext);
  const [currStep, setCurrStep] = useState(content[navIndex]);
  const [isGoalLoading, setIsGoalLoading] = useState(false);
  const [titleVal, setTitleVal] = useState("");
  const [bodyVal, setBodyVal] = useState("");

  useEffect(() => {
    setCurrStep(content[navIndex]);
  }, [navIndex, content]);

  useEffect(() => {
    if (content[navIndex].title) {
      setTitleVal(content[navIndex].title);
    } else {
      setTitleVal("");
    }
    if (content[navIndex].body) {
      setBodyVal(content[navIndex].body);
    } else {
      setBodyVal("");
    }
  }, [navIndex]);

  const onGoalChange = async (goalTitle) => {
    setIsGoalLoading(true);

    let foundGoal = goalList.find((goal) => `${goal.title}` == `${goalTitle}`);

    modContent({
      index: navIndex,
      value: {
        goal: {
          title: goalTitle,
          stepNumber: foundGoal == null ? 0 : foundGoal.steps.length,
        },
      },
    });

    // If selected an existing goal and that goal is not the last goal, we must update the content array order
    if (foundGoal) {
      console.log("found goal");
      console.log(foundGoal);
      const stepDirectlyAhead = content.findIndex((step) => {
        if (
          `${step.goal?.title}` == `${foundGoal.title}` &&
          step.goal?.stepNumber == foundGoal.steps.length - 1
        ) {
          return true;
        }
      });

      // REMOVE + 1 whenever the step being moved is ahead of the to index
      const toIndex =
        navIndex < stepDirectlyAhead
          ? stepDirectlyAhead
          : stepDirectlyAhead + 1;
      await moveStep({ fromIndex: navIndex, toIndex: toIndex });
      await navigateContent({ index: toIndex });
    } else if (!foundGoal) {
      console.log("not found goal");
      await moveStep({ fromIndex: navIndex, toIndex: content.length - 1 });
      await navigateContent({ index: content.length - 1 });
    }

    setIsGoalLoading(false);
  };

  const onContentChange = ({ label, labelValue }) => {
    modContent({
      index: navIndex,
      value: {
        [label]: labelValue,
      },
    });

    if (content[navIndex].models != null) {
      content[navIndex].models.forEach((item, index) => {
        modContentModel({
          index: navIndex,
          value: {
            [label]: labelValue,
          },
          modelIndex: index,
        });
      });
    } else if (content[navIndex].model != null) {
      modContentModel({
        index: navIndex,
        value: {
          [label]: labelValue,
        },
      });
    }
  };

  return (
    <Box
      bg="bg-surface"
      px={{
        base: "8",
        md: "12",
      }}
      py="10"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      borderTopWidth="4px"
      borderColor="accent"
    >
      <Stack spacing="1">
        <FormControl>
          <FormLabel>Goal Title (Nested Under)</FormLabel>
          <CreatableSelect
            isInvalid={!currStep?.goal?.title || currStep?.goal?.title == ""}
            isMulti={false}
            value={
              currStep.goal?.title != null
                ? { label: currStep?.goal?.title, value: currStep?.goal?.title }
                : undefined
            }
            name="Goals"
            options={goalList.map((goal) => {
              return { label: goal.title, value: goal.title };
            })}
            onCreateOption={(o) => onGoalChange(o)}
            placeholder="Select or create a goal..."
            closeMenuOnSelect={true}
            onChange={(op) => onGoalChange(op.value)}
            isLoading={isGoalLoading}
          />
        </FormControl>

        <View style={{ marginVertical: 20 }} />

        <FormControl>
          <Input
            id="stepTitle"
            size="md"
            placeholder=" "
            data-peer
            onChange={(e) => {
              setTitleVal(e.target.value);
              onContentChange({ label: "title", labelValue: e.target.value });
            }}
            value={titleVal}
          />
          <FormLabel htmlFor="stepTitle" variant="floating" size="md">
            Step Title
          </FormLabel>
        </FormControl>

        <View style={{ marginVertical: 10 }} />

        <FormControl>
          <Textarea
            id="stepBody"
            placeholder="Describe the step. This will be displayed on the left-hand side of the page, alongside the step component. Pro tip: use double brackets (i.e., {{ }}) to reference any variable created in other steps, or execute any JavaScript code."
            size="md"
            style={{ minHeight: 200 }}
            onChange={(e) => {
              setBodyVal(e.target.value);
              onContentChange({ label: "body", labelValue: e.target.value });
            }}
            value={bodyVal}
          />
        </FormControl>

        {content[navIndex].model != null || content[navIndex].models != null ? (
          <View style={{ marginTop: 20 }}>
            <FormControl id="view-select">
              <FormLabel>Step View</FormLabel>

              <Select
                placeholder="Select a Step View"
                value={
                  content[navIndex]?.model && content[navIndex].model.view
                    ? content[navIndex].model.view
                    : ""
                }
                onChange={(e) => {
                  if (e.target.value == null || e.target.value == "") {
                    console.log("received null value select");
                  } else {
                    onContentChange({
                      label: "view",
                      labelValue: e.target.value,
                    });
                  }
                }}
              >
                {bankSchemas.Step?.schema
                  ?.find((i) => i.schemaTitle == "view")
                  ?.details?.enum.map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i.charAt(0).toUpperCase() + i.slice(1)}
                      </option>
                    );
                  })}
              </Select>
            </FormControl>
            <View style={{ marginVertical: 20 }} />

            <FormControl id="view-details">
              <FormLabel>Navigation Mode</FormLabel>

              <Select
                placeholder="Select a Navigation Mode"
                value={
                  content[navIndex].model?.navigationMode
                    ? content[navIndex].model?.navigationMode
                    : ""
                }
                onChange={(e) => {
                  if (e.target.value == null || e.target.value == "") {
                    console.log("received null value select");
                  } else {
                    onContentChange({
                      label: "navigationMode",
                      labelValue: e.target.value,
                    });
                  }
                }}
              >
                {bankSchemas.Step?.schema
                  ?.find((i) => i.schemaTitle == "navigationMode")
                  ?.details?.enum.map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i.charAt(0).toUpperCase() + i.slice(1)}
                      </option>
                    );
                  })}
              </Select>
            </FormControl>
          </View>
        ) : null}
      </Stack>
    </Box>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: 30,
    fontWeight: "500",
    marginBottom: 20,
  },
});

export default StepDetails;
