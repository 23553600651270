import {
  Badge,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import {
  FiArrowDownRight,
  FiArrowUpRight,
  FiMoreVertical,
} from "react-icons/fi";

export const StatItem = (props) => {
  const { label, value, delta, icon, ...boxProps } = props;
  return (
    <Box
      bg="bg-surface"
      borderRadius="lg"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      {...boxProps}
    >
      <Box
        px={{
          base: "4",
          md: "6",
        }}
        py={{
          base: "5",
          md: "6",
        }}
      >
        <Stack>
          <HStack justify="space-between">
            <Text fontSize="sm" color="muted">
              {label}
            </Text>
          </HStack>
          <HStack justify="space-between">
            <Heading
              size={useBreakpointValue({
                base: "sm",
                md: "md",
              })}
            >
              {value}
            </Heading>
            {/* <Badge
              variant="subtle"
              colorScheme={delta.isUpwardsTrend ? "green" : "red"}
            >
              <HStack spacing="1">
                <Icon
                  as={delta.isUpwardsTrend ? FiArrowUpRight : FiArrowDownRight}
                />
                <Text>{delta.value}</Text>
              </HStack>
            </Badge> */}
          </HStack>
        </Stack>
      </Box>
    </Box>
  );
};
