import _ from "lodash";

const omitKeys = ["title", "body", "dbKey", "view"];
const baseTypes = [
  "String",
  "Number",
  "Boolean",
  "string",
  "number",
  "boolean",
];

const handleBlocks = (
  node,
  val,
  uiSchema,
  labels,
  bank,
  parent,
  displayParent,
  uiParent
) => {
  // Call normal recursive on compType
  console.log(node.schema.find((i) => `${i.schemaTitle}` == "compType"));
  recursiveClean(
    val,
    uiSchema,
    labels,
    bank,
    [node.schema.find((i) => `${i.schemaTitle}` == "compType")],
    parent != null
      ? `${parent}.${node.schemaTitle}.items.properties`
      : `${node.schemaTitle}.items.properties`,
    parent != null
      ? `${displayParent}.${node.schemaTitle}`
      : `${node.schemaTitle}`,
    parent != null
      ? `${uiParent}.${node.schemaTitle}.items`
      : `${node.schemaTitle}.items`
  );
  // Add to dependencies
  const dataTypes = node.schema.find(
    (i) => `${i.schemaTitle}` == "data"
  ).schema;
  node.schema
    .find((i) => `${i.schemaTitle}` == "compType")
    .details.enum.forEach((elem, index) => {
      const arrPath =
        parent != null
          ? `${parent}.${node.schemaTitle}.items.dependencies.compType.oneOf`
          : `${node.schemaTitle}.items.dependencies.compType.oneOf`;
      _.set(val, arrPath, [
        ..._.get(val, arrPath),
        {
          properties: {
            compType: {
              enum: [elem],
            },
            data: {
              type: "object",
              properties: {},
              required: [],
            },
          },
        },
      ]);
      const propertyPath = `${arrPath}[${index}].properties.data.properties`;
      const elemSchema = [];
      dataTypes.forEach((dType) => {
        let labelOb =
          parent != null
            ? labels[
                `${displayParent}.${node.schemaTitle}.data.${dType.schemaTitle}`
              ]
            : labels[`${node.schemaTitle}.data.${dType.schemaTitle}`];
        if (node.schemaTitle == "nestedBlocks") {
          labelOb = labels[`${displayParent}.${dType.schemaTitle}`]
            ? labels[`${displayParent}.${dType.schemaTitle}`]
            : bank.bankLabels["elements-ui"][
                `blocks.data.${dType.schemaTitle}`
              ];
        }

        if (labelOb.parent != null && labelOb.parent.includes(`${elem}`)) {
          elemSchema.push({ ...dType });
        }
      });

      recursiveClean(
        val,
        uiSchema,
        labels,
        bank,
        elemSchema,
        propertyPath,
        parent != null
          ? `${displayParent}.${node.schemaTitle}.data`
          : `${node.schemaTitle}.data`,
        parent != null
          ? `${uiParent}.${node.schemaTitle}.items.data`
          : `${node.schemaTitle}.items.data`
      );
    });
};

const handleSuccess = (
  node,
  val,
  uiSchema,
  labels,
  bank,
  parent,
  displayParent,
  uiParent
) => {
  node.schema
    .filter((i) => `${i.schemaTitle}` != "successProps")
    .forEach((i) => {
      recursiveClean(
        val,
        uiSchema,
        labels,
        bank,
        [i],
        parent != null
          ? `${parent}.${node.schemaTitle}.items.properties`
          : `${node.schemaTitle}.items.properties`,
        parent != null
          ? `${displayParent}.${node.schemaTitle}`
          : `${node.schemaTitle}`,
        parent != null
          ? `${uiParent}.${node.schemaTitle}.items`
          : `${node.schemaTitle}.items`
      );
    });

  // Add to dependencies
  node.schema
    .find((i) => `${i.schemaTitle}` == "successComponent")
    .details.enum.forEach((elem, index) => {
      const arrPath =
        parent != null
          ? `${parent}.${node.schemaTitle}.items.dependencies.successComponent.oneOf`
          : `${node.schemaTitle}.items.dependencies.successComponent.oneOf`;

      _.set(val, arrPath, [
        ..._.get(val, arrPath),
        {
          properties: {
            successComponent: {
              enum: [elem],
            },
            successProps: {
              type: "object",
              properties: {},
              required: [],
            },
          },
        },
      ]);
      const propertyPath = `${arrPath}[${index}].properties.successProps.properties`;
      const elemSchema = _.cloneDeep(bank.bankSchemas[elem].schema);

      recursiveClean(
        val,
        uiSchema,
        labels,
        bank,
        elemSchema,
        propertyPath,
        parent != null
          ? `${displayParent}.${node.schemaTitle}.successProps`
          : `${node.schemaTitle}.successProps`,
        parent != null
          ? `${uiParent}.${node.schemaTitle}.items.successProps`
          : `${node.schemaTitle}.items.successProps`
      );
    });
};

const recursiveClean = (
  val,
  uiSchema,
  labels,
  bank,
  tree,
  parent,
  displayParent,
  uiParent
) => {
  console.log("RECEIVED TREE: ");
  console.log(tree);
  for (let node of tree) {
    /****
     *
     * OMIT
     *
     */
    if (parent == null && omitKeys.includes(`${node.schemaTitle}`)) continue;

    /****
     *
     * BASE TYPES
     *
     */
    if (baseTypes.includes(`${node.type}`)) {
      if (node.type == "String" && !node.details) {
        const path = parent
          ? `${uiParent}.${node.schemaTitle}`
          : node.schemaTitle;

        _.set(uiSchema, path, {
          "ui:widget": "textarea",
        });
        // uiSchema[path] = {
        //   "ui:widget": "textarea",
        // };
      }

      node.type = node.type.toLowerCase();
      let { schemaTitle, details, ...y } = node;
      if (parent) {
        _.set(val, `${parent}.${schemaTitle}`, {
          ...y,
          ...details,
          title: labels[`${displayParent}.${node.schemaTitle}`]
            ? labels[`${displayParent}.${node.schemaTitle}`].title
            : schemaTitle,
          description:
            labels[`${displayParent}.${node.schemaTitle}`]?.description,
        });

        const modParentPath = parent.substring(
          0,
          parent.lastIndexOf(".properties")
        );
        if (!labels[`${displayParent}.${node.schemaTitle}`]?.optional) {
          _.set(val, `${modParentPath}.required`, [
            ..._.get(val, `${modParentPath}.required`),
            schemaTitle,
          ]);
        }
      } else {
        val[node.schemaTitle] = {
          ...y,
          ...details,
          title: labels[`${node.schemaTitle}`]
            ? labels[`${node.schemaTitle}`].title
            : node.schemaTitle,
          description: labels[`${node.schemaTitle}`]?.description,
        };
        if (!labels[`${node.schemaTitle}`]?.optional) {
          _.set(val, "required", [...val["required"], node.schemaTitle]);
        }
      }
    }

    /****
     *
     * ARRAYS
     *
     */
    if (
      node.type == "ArrayOfSchema" ||
      node.type == "ArrayOfString" ||
      node.type == "ArrayOfNumber" ||
      node.type == "ArrayOfObject"
    ) {
      let arrayType = `${node.type}`;

      const newNode = { ...node };
      newNode.type = "array";

      let { schemaTitle, schema, ...y } = newNode;
      const labelsOb =
        parent != null
          ? labels[`${displayParent}.${schemaTitle}`]
          : labels[`${node.schemaTitle}`];

      let propertyItems =
        arrayType == "ArrayOfString"
          ? { type: "string" }
          : arrayType == "ArrayOfNumber"
          ? { type: "number" }
          : arrayType == "ArrayOfSchema" &&
            labelsOb &&
            labelsOb.signal == "blocks"
          ? {
              type: "object",
              properties: {},
              dependencies: {
                compType: {
                  oneOf: [],
                },
              },
              required: [],
            }
          : arrayType == "ArrayOfSchema" && node.schemaTitle == "response"
          ? {
              type: "object",
              properties: {},
              dependencies: {
                successComponent: {
                  oneOf: [],
                },
              },
              required: [],
            }
          : arrayType == "ArrayOfSchema" || arrayType == "ArrayOfObject"
          ? {
              type: "object",
              required: [],
            }
          : null;

      if (labelsOb?.additionalProperties != null) {
        console.log("FOUND ADDITIONAL PROPERTIES ARRAY *******");
        propertyItems = {
          ...propertyItems,
          additionalProperties: { type: labelsOb.additionalProperties },
        };
      }

      if (parent) {
        _.set(val, `${parent}.${schemaTitle}`, {
          ...y,
          title: labels[`${displayParent}.${schemaTitle}`]
            ? labels[`${displayParent}.${schemaTitle}`].title
            : schemaTitle,
          description: labels[`${displayParent}.${schemaTitle}`]?.description,
          items: propertyItems,
        });

        const modParentPath = parent.substring(
          0,
          parent.lastIndexOf(".properties")
        );
        if (!labels[`${displayParent}.${schemaTitle}`]?.optional) {
          _.set(val, `${modParentPath}.required`, [
            ..._.get(val, `${modParentPath}.required`),
            schemaTitle,
          ]);
        }

        if (
          labels[`${displayParent}.${schemaTitle}`] &&
          labels[`${displayParent}.${schemaTitle}`].signal == "blocks"
        ) {
          handleBlocks(
            newNode,
            val,
            uiSchema,
            labels,
            bank,
            parent,
            displayParent,
            uiParent
          );
        } else if (node.schemaTitle == "response") {
          handleSuccess(
            newNode,
            val,
            uiSchema,
            labels,
            bank,
            parent,
            displayParent,
            uiParent
          );
        } else if (
          (arrayType == "ArrayOfSchema" && node.schema != null) ||
          (arrayType == "ArrayOfObject" && node.schema != null)
        ) {
          let child = recursiveClean(
            val,
            uiSchema,
            labels,
            bank,
            node.schema,
            `${parent}.${node.schemaTitle}.items.properties`,
            `${displayParent}.${node.schemaTitle}`,
            `${uiParent}.${node.schemaTitle}.items`
          );
        }
      } else {
        val[node.schemaTitle] = {
          ...y,
          title: labels[`${node.schemaTitle}`]
            ? labels[`${node.schemaTitle}`].title
            : node.schemaTitle,
          description: labels[`${node.schemaTitle}`]?.description,
        };
        val[node.schemaTitle]["items"] = propertyItems;

        if (
          labels[`${node.schemaTitle}`] &&
          labels[`${node.schemaTitle}`].signal == "blocks"
        ) {
          handleBlocks(
            newNode,
            val,
            uiSchema,
            labels,
            bank,
            parent,
            displayParent,
            uiParent
          );
        } else if (node.schemaTitle == "response") {
          handleSuccess(
            newNode,
            val,
            uiSchema,
            labels,
            bank,
            parent,
            displayParent,
            uiParent
          );
        } else if (
          (arrayType == "ArrayOfSchema" && node.schema != null) ||
          (arrayType == "ArrayOfObject" && node.schema != null)
        ) {
          let child = recursiveClean(
            val,
            uiSchema,
            labels,
            bank,
            node.schema,
            `${node.schemaTitle}.items.properties`,
            `${node.schemaTitle}`,
            `${node.schemaTitle}.items`
          );
        }
      }
    }

    /****
     *
     * OBJECTS + SCHEMAS
     *
     */
    if (
      node.type == "Object" ||
      node.type == "Schema" ||
      node.type == "object"
    ) {
      node.type = "object";
      let { schemaTitle, schema, ...y } = node;

      let labelsOb =
        parent != null
          ? labels[`${displayParent}.${schemaTitle}`]
          : labels[`${node.schemaTitle}`];

      if (labelsOb == null) {
        console.log(`${displayParent}.${schemaTitle}`);
        // looking for pages.blocks.data.nestedBlocks.data.style or without pages
        const blocksLabel =
          bank.bankLabels["elements-ui"][`blocks.data.${schemaTitle}`];
        const pagesLabel =
          bank.bankLabels["page-elements-ui"][
            `pages.blocks.data.${schemaTitle}`
          ];

        if (blocksLabel != null) labelsOb = blocksLabel;
        if (pagesLabel != null) labelsOb = pagesLabel;
      }
      if (labelsOb?.additionalProperties != null) {
        console.log("FOUND ADDITIONAL PROPERTIES");
        y = {
          ...y,
          additionalProperties: { type: labelsOb.additionalProperties },
        };
      }

      if (parent) {
        _.set(val, `${parent}.${schemaTitle}`, {
          ...y,
          title: labels[`${displayParent}.${schemaTitle}`]
            ? labels[`${displayParent}.${schemaTitle}`].title
            : schemaTitle,
          description: labels[`${displayParent}.${schemaTitle}`]?.description,
          properties: {},
          required: [],
        });

        const modParentPath = parent.substring(
          0,
          parent.lastIndexOf(".properties")
        );
        if (!labels[`${displayParent}.${schemaTitle}`]?.optional) {
          _.set(val, `${modParentPath}.required`, [
            ..._.get(val, `${modParentPath}.required`),
            schemaTitle,
          ]);
        }

        if (node.schema != null) {
          let child = recursiveClean(
            val,
            uiSchema,
            labels,
            bank,
            node.schema,
            `${parent}.${node.schemaTitle}.properties`,
            `${displayParent}.${node.schemaTitle}`,
            `${uiParent}.${node.schemaTitle}`
          );
        }
      } else {
        val[node.schemaTitle] = {
          ...y,
          title: labels[`${node.schemaTitle}`]
            ? labels[`${node.schemaTitle}`].title
            : node.schemaTitle,
          description: labels[`${node.schemaTitle}`]?.description,
          properties: {},
          required: [],
        };

        if (node.schema != null) {
          let child = recursiveClean(
            val,
            uiSchema,
            labels,
            bank,
            node.schema,
            `${node.schemaTitle}.properties`,
            `${node.schemaTitle}`,
            `${node.schemaTitle}`
          );
        }
      }
    }

    /****
     *
     * OBJECT IDS
     *
     */
    // if (node.type == "ObjectId" && node.details.ref == "Success") {
    //   // HANDLING SUCCESS PROPS
    // }
  }
};

const translate = (schema, labels, bank) => {
  const val = { required: [] };
  const uiSchema = {};
  let prepLabels = labels == null ? {} : labels;
  recursiveClean(val, uiSchema, prepLabels, bank, [...schema]);
  const { required, ...y } = val;
  return {
    formSchema: {
      type: "object",
      properties: {
        ...y,
      },
      required: required,
    },
    uiSchema: {
      "ui:submitButtonOptions": {
        props: {
          disabled: false,
          className: "btn btn-info",
        },
        norender: true,
        submitText: "Submit",
      },
      ...uiSchema,
    },
  };
};

export { translate };
