import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, View, ActivityIndicator } from "react-native";
import { Context as ConstructContext } from "@context/ConstructContext";
import {
  Box,
  Button,
  Stack,
  Text as CText,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Tooltip,
  IconButton,
  Tag,
  Select,
} from "@chakra-ui/react";
import _ from "lodash";
import Form from "@rjsf/chakra-ui";

const OverviewDetails = () => {
  const {
    modContent,
    modPreload,
    modWorkers,
    state: { content, preload, workers, navIndex },
  } = useContext(ConstructContext);
  const [titleVal, setTitleVal] = useState("");
  const [bodyVal, setBodyVal] = useState("");
  const [preloadData, setPreloadData] = useState(-1);
  const [workersData, setWorkersData] = useState(-1);

  console.log(workersData);

  useEffect(() => {
    if (content[navIndex].title) {
      setTitleVal(content[navIndex].title);
    } else {
      setTitleVal("");
    }
    if (content[navIndex].body) {
      setBodyVal(content[navIndex].body);
    } else {
      setBodyVal("");
    }
    if (preload) {
      setPreloadData(preload);
    } else {
      setPreloadData(null);
    }
    if (workers) {
      setWorkersData(workers);
    } else {
      setWorkersData(null);
    }
  }, [navIndex]);

  const onContentChange = ({ label, labelValue }) => {
    modContent({
      index: 0,
      value: {
        [label]: labelValue,
      },
    });
  };

  return (
    <View>
      <Box
        bg="bg-surface"
        px={{
          base: "8",
          md: "12",
        }}
        py="10"
        boxShadow={useColorModeValue("sm", "sm-dark")}
        borderTopWidth="4px"
        borderColor="accent"
      >
        <Stack spacing="1">
          <FormControl>
            <FormLabel htmlFor="overviewTitle" size="md">
              Overview Title
            </FormLabel>
            <Input
              id="overviewTitle"
              size="md"
              placeholder="Hey {prospectCompany} team!"
              onChange={(e) => {
                setTitleVal(e.target.value);
                onContentChange({ label: "title", labelValue: e.target.value });
              }}
              value={titleVal}
            />
          </FormControl>

          <View style={{ marginVertical: 10 }} />

          <FormControl>
            <FormLabel htmlFor="overviewBody" size="md">
              Overview Body Text
            </FormLabel>
            <Textarea
              id="overviewBody"
              placeholder="This will be the welcome message displayed on the left-hand side of the first page, alongside the template outline. This is a good space to welcome the visitor and discuss the user journey. This text will be editable per prospect company."
              size="md"
              style={{ minHeight: 200 }}
              onChange={(e) => {
                setBodyVal(e.target.value);
                onContentChange({ label: "body", labelValue: e.target.value });
              }}
              value={bodyVal}
            />
          </FormControl>
        </Stack>
      </Box>
      <View style={{ marginVertical: 20 }} />
      <Box
        bg="bg-surface"
        px={{
          base: "8",
          md: "12",
        }}
        py="10"
        boxShadow={useColorModeValue("sm", "sm-dark")}
        borderTopWidth="4px"
        borderColor="accent"
      >
        <Stack spacing="1">
          {preloadData == -1 ? (
            <ActivityIndicator />
          ) : (
            <Form
              schema={{
                type: "object",
                properties: {
                  preload: {
                    type: "array",
                    title: "Template Variables",
                    items: {
                      type: "object",
                      properties: {
                        label: {
                          type: "string",
                          title: "Display Name (Instruction)",
                        },
                        fieldKey: {
                          type: "string",
                          title: "Field Name (Database Key)",
                        },
                        componentType: {
                          type: "string",
                          title: "Property Type",
                          enum: ["input", "json", "color", "eval"],
                          default: "input",
                        },
                        value: {
                          type: "string",
                          title: "Hardcoded Value",
                        },
                      },
                    },
                  },
                },
              }}
              formData={preloadData}
              uiSchema={{
                "ui:submitButtonOptions": {
                  props: {
                    disabled: false,
                    className: "btn btn-info",
                  },
                  norender: true,
                  submitText: "Submit",
                },
                preload: {
                  items: {
                    value: {
                      "ui:widget": "textarea",
                    },
                  },
                },
              }}
              onChange={(e) => {
                setPreloadData(e.formData);
                modPreload(e.formData);
              }}
            />
          )}
        </Stack>
      </Box>
      <View style={{ marginVertical: 20 }} />
      <Box
        bg="bg-surface"
        px={{
          base: "8",
          md: "12",
        }}
        py="10"
        boxShadow={useColorModeValue("sm", "sm-dark")}
        borderTopWidth="4px"
        borderColor="accent"
      >
        <Stack spacing="1">
          {workersData == -1 ? (
            <ActivityIndicator />
          ) : (
            <Form
              schema={{
                type: "object",
                properties: {
                  triggers: {
                    type: "array",
                    title: "Template Triggers",
                    items: {
                      type: "object",
                      properties: {
                        dbPath: {
                          type: "string",
                          title: "Database Path to Lookup",
                        },

                        conditionType: {
                          type: "string",
                          title: "Condition Type",
                          enum: ["exists", "equals", "onChange", "transform"],
                          default: "exists",
                        },
                        conditionValue: {
                          type: "string",
                          title: "Condition Value for Comparison",
                        },
                        liveData: {
                          type: "object",
                          properties: {
                            requestCode: {
                              type: "string",
                              title: "cURL Request",
                            },
                            requestFields: {
                              type: "array",
                              title: "Request Fields",
                              items: {
                                type: "object",
                                properties: {
                                  codeKey: {
                                    type: "string",
                                    title: "Code Key",
                                  },
                                  value: {
                                    type: "string",
                                    title: "Initial Value",
                                  },
                                  loadValue: {
                                    type: "string",
                                    title: "Database Path to Load Value",
                                  },
                                },
                              },
                            },
                            responseCode: {
                              type: "string",
                              title: "JSON Response",
                            },
                            displayUrl: {
                              type: "string",
                              title: "Request URL to Display on Live Calls",
                            },
                            dbKey: {
                              type: "string",
                              title: "DB Key",
                            },
                            fieldKey: {
                              type: "string",
                              title: "Field Key",
                            },
                          },
                        },
                      },
                    },
                  },
                },
              }}
              formData={workersData}
              uiSchema={{
                "ui:submitButtonOptions": {
                  props: {
                    disabled: false,
                    className: "btn btn-info",
                  },
                  norender: true,
                  submitText: "Submit",
                },

                triggers: {
                  items: {
                    liveData: {
                      requestCode: {
                        "ui:widget": "textarea",
                      },
                      responseCode: {
                        "ui:widget": "textarea",
                      },
                    },
                  },
                },
              }}
              onChange={(e) => {
                setWorkersData(e.formData);
                modWorkers(e.formData);
              }}
            />
          )}
        </Stack>
      </Box>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: 30,
    fontWeight: "500",
    marginBottom: 20,
  },
});

export default OverviewDetails;
