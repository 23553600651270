import {
  Box,
  Circle,
  Flex,
  Stack,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  BiBuoy,
  BiCommentAdd,
  BiHome,
  BiChart,
  BiWrench,
} from "react-icons/bi";
import { AccountSwitcher } from "./AccountSwitcher";
import { NavGroup } from "./NavGroup";
import { NavItem } from "./NavItem";
import * as RootNavigation from "../../RootNavigation";
import { H } from "highlight.run";
import { toast } from "react-toastify";
import posthog from "posthog-js";

export const NavBar = ({ navChange }) => {
  const [currentRoute, setCurrentRoute] = useState("");

  useEffect(() => {
    setCurrentRoute(
      RootNavigation.navigationRef.current?.getCurrentRoute()?.name
    );
    posthog.capture("$pageview");
  }, [navChange]);

  return (
    <Box w="64" bg="bg-surface" fontSize="sm">
      <Flex h="full" direction="column" px="4" py="4">
        <AccountSwitcher />
        <Stack spacing="8" flex="1" overflow="auto" pt="8">
          <Stack spacing="1">
            <NavItem
              active={currentRoute == "Home"}
              icon={<BiHome />}
              label="Home"
              onClick={() => {
                RootNavigation.navigate("Home");
              }}
            />
          </Stack>
          <NavGroup label="Prospects">
            <NavItem
              active={currentRoute == "Create"}
              icon={<BiCommentAdd />}
              label="Create New"
              onClick={() => RootNavigation.navigate("Create")}
            />
            <NavItem
              active={currentRoute == "Track"}
              icon={<BiChart />}
              label="Track All"
              onClick={() => RootNavigation.navigate("Track")}
            />
          </NavGroup>
          <NavGroup label="Templates">
            {process.env.NODE_ENV === "production" ||
            process.env.REACT_APP_ENV === "STAGING" ? (
              <NavItem
                active={currentRoute == "Build"}
                icon={<BiWrench />}
                label="Builder (Coming Soon)"
                onClick={() =>
                  toast(
                    "Builder tools coming soon! Contact support@trycoast.com with questions."
                  )
                }
              />
            ) : (
              <NavItem
                active={currentRoute == "Build"}
                icon={<BiWrench />}
                label="Builder"
                onClick={() => RootNavigation.navigate("Build")}
              />
            )}
          </NavGroup>

          {/* <NavGroup label="Seller Tools">
            <NavItem icon={<BiNews />} label="Payment Pages" />
            <NavItem icon={<BiEnvelope />} label="Invoices" />
            <NavItem icon={<BiPurchaseTagAlt />} label="Plans" />
            <NavItem icon={<BiRecycle />} label="Subscription" />
          </NavGroup> */}
        </Stack>
        <Box>
          <Stack spacing="1">
            {/* <NavItem subtle icon={<BiCog />} label="Settings" /> */}
            <NavItem
              subtle
              icon={<BiBuoy />}
              label="Help & Support"
              onClick={() => {
                H.toggleSessionFeedbackModal();
              }}
              // endElement={<Circle size="2" bg="blue.400" />}
            />
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
};
