import {
  Box,
  Button,
  Divider,
  HStack,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Container,
  Stack,
  useColorModeValue,
  Text,
  Tabs,
  Tab,
  TabList,
} from "@chakra-ui/react";
import { StyleSheet, View, Image, ActivityIndicator } from "react-native";
import React, { useState, useEffect, useContext } from "react";
import { Context as ConstructContext } from "@context/ConstructContext";
import { NavGroup } from "../../components/Navigation/NavGroup";
import { NavItem } from "../../components/Navigation/NavItem";

import { FiPlus } from "react-icons/fi";

const ContentOverview = ({ w, templateName }) => {
  const {
    addContent,
    navigateContent,
    createTemplate,
    state: { content, navIndex, goalList, editingMode },
  } = useContext(ConstructContext);

  return (
    <Box
      as="form"
      bg="bg-surface"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      borderRadius="lg"
      w={w}
      fontSize="sm"
    >
      <View
        style={{
          padding: 30,
          minHeight: 400,
          maxHeight: 600,
          overflowY: "scroll",
        }}
      >
        <Text fontSize="l" fontWeight={"medium"}>
          {templateName == "" ? "Template Outline" : `${templateName}`}
        </Text>
        {editingMode == "update" ? (
          <Text fontSize="l" fontWeight={"medium"} style={{ color: "red" }}>
            Updating Mode
          </Text>
        ) : null}

        <View style={{ marginVertical: 10 }} />

        <NavItem
          key={"overview"}
          active={navIndex == 0}
          icon={
            <View>
              <Text fontSize={"sm"}>1</Text>
            </View>
          }
          label={"Overview"}
          onClick={() => {
            navigateContent({ index: 0 });
          }}
        />

        {goalList.map((goal, index) => {
          return (
            <View key={goal.title ? goal.title : index}>
              <View style={{ marginVertical: 10 }} />
              <NavGroup label={goal.title}>
                {goal.steps.map((step, index) => {
                  return (
                    <NavItem
                      key={step.title}
                      active={step.navIndex == navIndex}
                      icon={
                        <View>
                          <Text fontSize={"sm"}>{index + 1}</Text>
                        </View>
                      }
                      label={step.title}
                      onClick={() => {
                        navigateContent({ index: step.navIndex });
                      }}
                    />
                  );
                })}
              </NavGroup>
            </View>
          );
        })}
        <View style={{ marginVertical: 10 }} />
        <HStack>
          <Divider />
          <Button
            flexShrink={0}
            variant="secondary"
            leftIcon={<FiPlus fontSize="1.25rem" />}
            onClick={() => {
              addContent();
            }}
          >
            Add Step
          </Button>
          <Divider />
        </HStack>

        <View style={{ marginVertical: 20 }} />

        <Button
          variant="primary"
          onClick={() => {
            createTemplate();
          }}
        >
          Create
        </Button>
      </View>
    </Box>
  );
};

export default ContentOverview;
