import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import * as React from "react";
import { StatItem } from "./StatItem";

export const StatGroup = ({ stats }) => (
  <Box
    as="section"
    py={{
      base: "4",
      md: "8",
    }}
  >
    <SimpleGrid
      columns={{
        base: 1,
        md: 3,
      }}
      gap={{
        base: "5",
        md: "6",
      }}
    >
      {stats.map((stat, id) => (
        <StatItem key={id} {...stat} />
      ))}
    </SimpleGrid>
  </Box>
);

export default StatGroup;
