import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, View, Text, Image, ActivityIndicator } from "react-native";
import { Context as ConstructContext } from "@context/ConstructContext";
import {
  Text as CText,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  Select,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FiArrowUp, FiArrowDown, FiTrash } from "react-icons/fi";
import _ from "lodash";
import { toast } from "react-toastify";

const StepOrder = () => {
  const {
    navigateContent,
    removeContent,
    moveStep,
    modContent,
    updateStepNumbers,
    state: { bankSchemas, content, navIndex, goalList, stepFlag },
  } = useContext(ConstructContext);
  const [alertContent, setAlertContent] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  useEffect(() => {
    updateStepNumbers();
  }, [content]);

  const onMoveUp = () => {
    console.log("Moving step up in order");
    const currStep = content[navIndex];
    if (currStep.goal.stepNumber != 0) {
      moveStep({ fromIndex: navIndex, toIndex: navIndex - 1 });
      navigateContent({ index: navIndex - 1 });
    } else {
      toast.error(
        "Step is already first, or it's first in its goal. Try changing the goal."
      );
    }
  };

  const onMoveDown = () => {
    console.log("Moving step down in order");
    const currStep = content[navIndex];
    const foundGoal = goalList.find(
      (goal) => `${goal.title}` == `${currStep.goal.title}`
    );
    console.log(foundGoal);
    if (currStep.goal.stepNumber < foundGoal.steps.length - 1) {
      moveStep({ fromIndex: navIndex, toIndex: navIndex + 1 });
      navigateContent({ index: navIndex + 1 });
    } else {
      toast.error(
        "Step is already last, or it's last in its goal. Try changing the goal."
      );
    }
  };

  const onDelete = () => {
    console.log("Deleting step");
    navigateContent({ index: -1 });
    removeContent({ index: navIndex });
  };

  return (
    <View
      style={{
        marginVertical: 20,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
      }}
    >
      <CText fontSize={"md"} fontWeight="medium">
        Navigation Order No. {navIndex + 1}
      </CText>
      <View>
        <ButtonGroup isAttached variant="secondary">
          <Tooltip label="Move Up in Order">
            <IconButton
              value="up"
              aria-label="Show previous"
              icon={<FiArrowUp fontSize="1.25rem" />}
              onClick={() => {
                setAlertContent({ text: "Move Step Up", func: onMoveUp });
                onOpen();
              }}
            />
          </Tooltip>
          <Tooltip label="Move Down in Order">
            <IconButton
              value="down"
              aria-label="Show next"
              icon={<FiArrowDown fontSize="1.25rem" />}
              onClick={() => {
                setAlertContent({ text: "Move Step Down", func: onMoveDown });
                onOpen();
              }}
            />
          </Tooltip>

          <Tooltip label="Delete">
            <IconButton
              value="remove"
              aria-label="Show next"
              icon={<FiTrash fontSize="1.25rem" />}
              onClick={() => {
                setAlertContent({ text: "Delete Step", func: onDelete });
                onOpen();
              }}
            />
          </Tooltip>
        </ButtonGroup>
      </View>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm {alertContent.text}
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="purple"
                onClick={() => {
                  alertContent.func();
                  onClose();
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: 30,
    fontWeight: "500",
    marginBottom: 20,
  },
});

export default StepOrder;
