import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { View, Image, TouchableOpacity, ActivityIndicator } from "react-native";
import React, { useContext, useState, useEffect } from "react";
import { Context as AuthContext } from "@context/AuthContext";
import * as RootNavigation from "../../../RootNavigation";
import { toast } from "react-toastify";
import { H } from "highlight.run";
import * as EmailValidator from "email-validator";

const SignUpScreen = ({ route }) => {
  const {
    signup,
    clearErrorMessage,
    fetchCompany,
    state: { errorMessage, company },
  } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyKey, setCompanyKey] = useState("");
  const companyId =
    route.params &&
    route.params.companyId &&
    route.params.companyId != undefined &&
    route.params.companyId != ""
      ? route.params.companyId
      : null;

  useEffect(() => {
    async function loadCompany() {
      console.log("Identifying company");
      await fetchCompany({ companyId });
    }
    loadCompany();
  }, [companyId]);

  useEffect(() => {
    if (errorMessage != "") {
      setIsLoading(false);
      clearErrorMessage();
    }
  }, [errorMessage]);

  const validateInputs = () => {
    if (
      firstName == "" ||
      lastName == "" ||
      email == "" ||
      password == "" ||
      confirmPassword == "" ||
      companyKey == ""
    ) {
      toast.error("All fields must be completed");
      return false;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return false;
    }
    if (password.length < 8) {
      toast.error("Password must be at least 8 characters");
      return false;
    }
    if (!EmailValidator.validate(email)) {
      toast.error("Email is invalid");
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    if (isLoading == true) return;
    setIsLoading(true);
    const isValidated = validateInputs();
    if (isValidated) {
      await signup({
        firstName,
        lastName,
        email,
        password,
        companyId: company._id,
        companyKey,
      });
    }
  };

  const companyDetail = () => {
    if (!company || company == null) {
      return (
        <View>
          <ActivityIndicator />
          <View style={{ marginVertical: 10 }} />
          <Text style={{ textAlign: "center" }}>Identifying Company</Text>
        </View>
      );
    } else if (company != null && company.error != "") {
      return (
        <View>
          <Text style={{ textAlign: "center" }}>
            Invalid sign up link. Please contact your account admin, or
            support@trycoast.com for help.
          </Text>
        </View>
      );
    }
  };

  return (
    <Container
      maxW="lg"
      py={{
        base: "12",
        md: "12",
      }}
      px={{
        base: "0",
        sm: "8",
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6" align="center">
          <Image
            source={require("../../../../assets/Coast-Logo.svg")}
            style={{
              width: 125,
              resizeMode: "contain",
              height: 30,
            }}
          />
          <View style={{ marginVertical: 10 }} />
          <Stack spacing="3" textAlign="center">
            <Heading
              size={useBreakpointValue({
                base: "xs",
                md: "sm",
              })}
            >
              Create an account
              {company != null && company._id ? ` for ${company.name}` : null}
            </Heading>
            <HStack justify="center" spacing="1">
              <Text fontSize="sm" color="muted">
                Already have an account?
              </Text>
              <Button
                variant="link"
                colorScheme="blue"
                size="sm"
                onClick={() => RootNavigation.navigate("Login")}
              >
                Log in
              </Button>
            </HStack>
          </Stack>
        </Stack>
        <Box
          py={{
            base: "0",
            sm: "8",
          }}
          px={{
            base: "4",
            sm: "10",
          }}
          bg={useBreakpointValue({
            base: "transparent",
            sm: "bg-surface",
          })}
          boxShadow={{
            base: "none",
            sm: useColorModeValue("md", "md-dark"),
          }}
          borderRadius={{
            base: "none",
            sm: "xl",
          }}
        >
          <Stack spacing="6">
            {company != null && company._id ? (
              <>
                <Stack spacing="5">
                  <FormControl isRequired>
                    <FormLabel htmlFor="firstName">First Name</FormLabel>
                    <Input
                      id="firstName"
                      type="text"
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel htmlFor="lastName">Last Name</FormLabel>
                    <Input
                      id="lastName"
                      type="text"
                      value={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input
                      id="email"
                      type="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <Input
                      id="password"
                      type="password"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <FormHelperText color="muted">
                      At least 8 characters long
                    </FormHelperText>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel htmlFor="confirmPassword">
                      Confirm Password
                    </FormLabel>
                    <Input
                      id="confirmPassword"
                      type="password"
                      value={confirmPassword}
                      onChange={(event) =>
                        setConfirmPassword(event.target.value)
                      }
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel htmlFor="companyKey">Company Key</FormLabel>
                    <Input
                      id="companyKey"
                      type="password"
                      value={companyKey}
                      onChange={(event) => setCompanyKey(event.target.value)}
                    />
                  </FormControl>
                </Stack>
                <Stack spacing="4">
                  <Button
                    variant="primary"
                    onClick={() => onSubmit()}
                    isLoading={isLoading}
                  >
                    Create account
                  </Button>
                </Stack>
              </>
            ) : (
              <>{companyDetail()}</>
            )}
          </Stack>
        </Box>
        <TouchableOpacity onPress={() => H.toggleSessionFeedbackModal()}>
          <Text fontSize="xs" color="muted">
            Click here or contact support@trycoast.com for help
          </Text>
        </TouchableOpacity>
      </Stack>
    </Container>
  );
};

export default SignUpScreen;
