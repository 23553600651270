import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, View, Text, Image, ActivityIndicator } from "react-native";
import { Context as CompanyContext } from "@context/CompanyContext";
import ProposalTable from "@components/ProposalTable/ProposalTable";
import { Text as CText } from "@chakra-ui/react";

const TrackScreen = () => {
  const {
    state: { plans },
  } = useContext(CompanyContext);

  return (
    <View
      style={{
        paddingTop: 40,
        paddingHorizontal: 40,
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <Text style={styles.header}>All Proposals</Text>
      {plans == null ? (
        <ActivityIndicator style={{ alignSelf: "center" }} />
      ) : plans.length == 0 ? (
        <CText color="muted">No proposals found</CText>
      ) : (
        <ProposalTable data={plans} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: 30,
    fontWeight: "500",
    marginBottom: 20,
  },
});

export default TrackScreen;
