import React from "react";
import { View, Text } from "react-native";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import AppNavigator from "./src/AppNavigator";

import { H } from "highlight.run";
import { ErrorBoundary } from "@highlight-run/react";
import "@highlight-run/react/dist/highlight.css";
// 1. Import the extendTheme function
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";
import "@fontsource/inter/variable.css";

import "@expo/match-media";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Provider as AuthProvider } from "./src/context/AuthContext";
import { Provider as UserProvider } from "./src/context/UserContext";
import { Provider as CompanyProvider } from "./src/context/CompanyContext";
import { Provider as ConstructProvider } from "./src/context/ConstructContext";
import posthog from "posthog-js";

import { GlobalDebug } from "./src/helpers/removeConsoles";

Sentry.init({
  dsn: "https://c6fbf6154e824888a41b228827eb4eea@o373137.ingest.sentry.io/6660425",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

if (process.env.ENVIRONMENT !== "dev") {
  H.init("7e3v6qg1", {
    environment: "production",
    enableStrictPrivacy: false,
    feedbackWidget: {
      enabled: true,
      title: "Got Feedback?",
      subTitle: "We appreciate all of your feedback!",
      submitButtonLabel: "Send Feedback",
      onSubmit: (name, email, text) => {
        toast(
          "Got it! We monitor submission around the clock, and we'll be in touch as soon as possible."
        );
      },
    },
  });

  H.getSessionURL().then((sessionUrl) => {
    Sentry.setContext("highlight", {
      url: sessionUrl,
    });
  });
}

posthog.init("phc_XWBL8lbhwuICVZ7mdmjjKo6n8uPXflty0OQ7DsiGev5", {
  api_host: "https://app.posthog.com",
});

function App() {
  React.useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  });

  const myTheme = extendTheme(
    {
      colors: { ...theme.colors, brand: theme.colors.purple },
    },
    theme
  );

  return (
    <ErrorBoundary showDialog>
      <AuthProvider>
        <UserProvider>
          <ConstructProvider>
            <CompanyProvider>
              <ChakraProvider theme={myTheme}>
                <ToastContainer />
                <AppNavigator />
              </ChakraProvider>
            </CompanyProvider>
          </ConstructProvider>
        </UserProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
