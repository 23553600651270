import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toast } from "react-toastify";

const userReducer = (state, action) => {
  switch (action.type) {
    case "fetch_user":
      return {
        ...state,
        userInfo: action.payload,
        isUserInfoLoaded: true,
      };
    case "reset_user_info":
      return {
        isUserInfoLoaded: false,
        userInfo: null,
      };
    default:
      return state;
  }
};

const fetchUserInfo = (dispatch) => async () => {
  //try {
  console.log("FETCHING USER INFO");
  const response = await serverApi.get("/user");
  dispatch({ type: "fetch_user", payload: response.data });
  await AsyncStorage.setItem("userId", response.data._id);
  // } catch (err) {
  //   console.log("fetchUserInfo error");
  //   console.log(err);
  //   toast.error(
  //     "Having trouble connecting to the server... Try again in a few."
  //   );
  // }
};

const resetUserInfo = (dispatch) => async () => {
  dispatch({ type: "reset_user_info" });
};

export const { Provider, Context } = createDataContext(
  userReducer,
  {
    fetchUserInfo,
    resetUserInfo,
  },
  {
    isUserInfoLoaded: false,
    userInfo: null,
  }
);
