import {
  Avatar,
  Badge,
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { Context as CompanyContext } from "@context/CompanyContext";
import { Context as UserContext } from "@context/UserContext";
import { IoArrowDown } from "react-icons/io5";
import { BiCopy } from "react-icons/bi";
import { FiEye, FiEdit2, FiTrash2, FiLock } from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import moment from "moment";
import DeleteModal from "./DeleteModal";
import * as RootNavigation from "../../RootNavigation";

const MemberTable = (props) => {
  const {
    setEditingPlan,
    state: { company, ownerPlans },
  } = useContext(CompanyContext);
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [planModalOpen, setPlanModalOpen] = useState(null);

  const formatStr = (str) => {
    if (str) {
      return str;
    } else {
      return "N/A";
    }
  };

  return (
    <Table {...props}>
      <Thead>
        <Tr>
          <Th>Company</Th>
          <Th>Contact</Th>
          <Th>Template</Th>
          <Th>Unique Viewers</Th>
          <Th>Total Page Views</Th>
          <Th>Avg. Session</Th>
          <Th>Total No. Sessions</Th>
          <Th>Avg. Page Views per Session</Th>
          <Th>Creator</Th>
          <Th>Link</Th>
          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text>Date</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.plans.map((plan) => {
          return (
            <Tr key={`${plan._id}`}>
              <Td>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TouchableOpacity
                    onPress={() =>
                      window.open(
                        company != null
                          ? `https://${company.domain}.coast.rest/${plan._id}`
                          : "https://google.com",
                        "_blank"
                      )
                    }
                  >
                    <IconButton
                      icon={<FiEye fontSize="1rem" />}
                      size="sm"
                      variant="ghost"
                      aria-label="View"
                    />
                  </TouchableOpacity>
                  <Text color="muted">{formatStr(plan.prospectCompany)}</Text>
                </View>
              </Td>
              <Td>
                <Text color="muted">{formatStr(plan.prospectName)}</Text>
              </Td>
              <Td>
                <Badge size="sm" colorScheme={"purple"}>
                  {formatStr(plan.template.templateName)}
                </Badge>
              </Td>
              <Td>
                <Text color="muted">
                  {formatStr(plan.stats?.uniqueViewers)}
                </Text>
              </Td>
              <Td>
                <Text color="muted">
                  {formatStr(plan.stats?.totalPageViews)}
                </Text>
              </Td>
              <Td>
                <Text color="muted">
                  {formatStr(plan.stats?.avgSessionDuration)} min
                </Text>
              </Td>
              <Td>
                <Text color="muted">
                  {formatStr(plan.stats?.totalSessions)}
                </Text>
              </Td>
              <Td>
                <Text color="muted">
                  {formatStr(plan.stats?.avgPageViewsPerSession)}
                </Text>
              </Td>
              <Td>
                <HStack spacing="3">
                  <Avatar
                    name={`${formatStr(plan.owner.firstName)} ${formatStr(
                      plan.owner.lastName
                    )}`}
                    src={""}
                    size="xs"
                  />
                  <Box>
                    <Text fontWeight="medium">{`${formatStr(
                      plan.owner.firstName
                    )} ${formatStr(
                      plan.owner.lastName.substring(0, 1)
                    )}.`}</Text>
                    <Text color="muted">{plan.handle}</Text>
                  </Box>
                </HStack>
              </Td>

              <Td>
                <CopyToClipboard
                  text={
                    company != null
                      ? `https://${company.domain}.coast.rest/${plan._id}`
                      : "https://google.com"
                  }
                  onCopy={() => toast("Link copied to clipboard")}
                >
                  <IconButton
                    icon={<BiCopy fontSize="1.25rem" />}
                    variant="ghost"
                  />
                </CopyToClipboard>
              </Td>

              <Td>
                <Text color="muted">
                  {moment(plan.createdAt).format("MM/DD/YYYY, h:mm a")}
                </Text>
              </Td>

              {`${plan.owner?._id}` == `${userInfo._id}` ||
              plan.metadata?.editingPermission == "team" ? (
                <Td>
                  <HStack spacing="1">
                    <TouchableOpacity
                      onPress={() => setPlanModalOpen(plan._id)}
                    >
                      <IconButton
                        icon={<FiTrash2 fontSize="1.25rem" />}
                        variant="ghost"
                        aria-label="Delete"
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        setEditingPlan(plan);
                        RootNavigation.navigate("Edit");
                      }}
                    >
                      <IconButton
                        icon={<FiEdit2 fontSize="1.25rem" />}
                        variant="ghost"
                        aria-label="Edit"
                      />
                    </TouchableOpacity>
                  </HStack>

                  <DeleteModal
                    plan={plan}
                    isOpen={planModalOpen == plan._id}
                    onClose={setPlanModalOpen}
                  />
                </Td>
              ) : (
                <Td>
                  <HStack spacing="1">
                    <Tooltip label="Owner has not granted team editing permissions">
                      <IconButton
                        icon={<FiLock fontSize="1.25rem" />}
                        variant="ghost"
                      />
                    </Tooltip>
                  </HStack>
                </Td>
              )}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default MemberTable;
