import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { Context as ConstructContext } from "@context/ConstructContext";
import ContentOverview from "./ContentOverview";
import {
  Box,
  Button,
  Stack,
  Text as CText,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Tooltip,
  IconButton,
  Tag,
  Select,
} from "@chakra-ui/react";
import _ from "lodash";

const ComponentDetails = ({ modelIndex }) => {
  const {
    modContentModel,
    initializeContent,
    state: { bankSchemas, stepSchemas, content, navIndex },
  } = useContext(ConstructContext);
  const [dbKeyVal, setDbKeyVal] = useState("");

  useEffect(() => {
    if (
      modelIndex != null &&
      content[navIndex].models != null &&
      content[navIndex].models[modelIndex]?.dbKey
    ) {
      setDbKeyVal(content[navIndex].models[modelIndex]?.dbKey);
    } else if (content[navIndex].model?.dbKey) {
      setDbKeyVal(content[navIndex].model.dbKey);
    } else {
      setDbKeyVal("");
    }
  }, [navIndex]);

  const onContentChange = ({ label, labelValue }) => {
    if (modelIndex != null && content[navIndex].models != null) {
      modContentModel({
        index: navIndex,
        value: {
          [label]: labelValue,
        },
        modelIndex: modelIndex,
      });
    } else if (content[navIndex].model != null) {
      modContentModel({
        index: navIndex,
        value: {
          [label]: labelValue,
        },
      });
    }
  };

  const getComponent = () => {
    if (modelIndex != null) {
      if (
        content[navIndex].models != null &&
        content[navIndex].models != undefined
      ) {
        if (modelIndex >= content[navIndex].models.length) {
          return "";
        } else {
          console.log(content[navIndex].models[modelIndex]);
          return content[navIndex].models[modelIndex].component;
        }
      } else {
        return "";
      }
    } else {
      if (
        content[navIndex].model != null &&
        content[navIndex].model != undefined
      ) {
        return content[navIndex].model.component;
      } else {
        return "";
      }
    }
  };

  return (
    <Box
      bg="bg-surface"
      px={{
        base: "8",
        md: "12",
      }}
      py="10"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      borderTopWidth="4px"
      borderColor="accent"
    >
      <Stack spacing="1">
        <FormControl id="componentSelect">
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 5,
            }}
          >
            <FormLabel>Component</FormLabel>
            <TouchableOpacity>
              <Tag>View Component Library</Tag>
            </TouchableOpacity>
          </View>

          <Select
            placeholder="Select a Step Component"
            value={getComponent()}
            onChange={(e) => {
              if (e.target.value == null || e.target.value == "") {
                console.log("received null value select");
                initializeContent({
                  index: navIndex,
                  componentName: null,
                  modelIndex,
                });
              } else {
                initializeContent({
                  index: navIndex,
                  componentName: e.target.value,
                  modelIndex,
                });
              }
            }}
          >
            {_.keys(stepSchemas).map((i) => {
              return (
                <option key={i} value={i}>
                  {i.charAt(0).toUpperCase() + i.slice(1)}
                </option>
              );
            })}
          </Select>
        </FormControl>

        <View style={{ marginVertical: 10 }} />

        <Tooltip
          closeOnClick={false}
          label="This can be anything you would like! But make sure to make it unique to this step, in case you want to reference the state in this step at any point. (Ex: 'step-title-lending')"
        >
          <FormControl>
            <Input
              id="dbKey"
              size="md"
              placeholder=" "
              data-peer
              onChange={(e) => {
                setDbKeyVal(e.target.value);
                onContentChange({ label: "dbKey", labelValue: e.target.value });
              }}
              value={dbKeyVal}
            />

            <FormLabel htmlFor="dbKey" variant="floating" size="md">
              Database Key
            </FormLabel>
          </FormControl>
        </Tooltip>
      </Stack>
    </Box>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: 30,
    fontWeight: "500",
    marginBottom: 20,
  },
});

export default ComponentDetails;
