import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";

const insertClose = (e) => {
  const pos = e.target.selectionStart;
  const val = [...e.target.value];

  const keyCode = e.key;
  let closeChar = null;
  if (keyCode == "{") closeChar = "}";

  if (closeChar) {
    val.splice(pos, 0, closeChar);
    e.target.value = val.join("");
    e.target.selectionEnd = pos;
  }
};

const StepCard = (props) => {
  const { item, index, contentList, handleContentChange } = props;
  const titleHandle = useRef(null);
  const bodyHandle = useRef(null);

  useEffect(() => {
    if (titleHandle.current)
      titleHandle.current.addEventListener("keydown", insertClose, false);
    if (bodyHandle.current)
      bodyHandle.current.addEventListener("keydown", insertClose, false);

    return () => {
      if (
        titleHandle.current != null &&
        typeof titleHandle.current.removeEventListener === "function"
      )
        titleHandle.current.removeEventListener("keydown", insertClose, false);
      if (
        bodyHandle.current != null &&
        typeof bodyHandle.current.removeEventListener === "function"
      )
        bodyHandle.current.removeEventListener("keydown", insertClose, false);
    };
  }, [titleHandle, bodyHandle]);

  return (
    <Box
      as="form"
      bg="bg-surface"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      borderRadius="lg"
      w={props.w}
    >
      <Stack
        spacing="5"
        px={{
          base: "4",
          md: "6",
        }}
        py={{
          base: "5",
          md: "6",
        }}
      >
        <Stack
          spacing="6"
          direction={{
            base: "column",
            md: "row",
          }}
        >
          <FormControl id="title">
            <FormLabel>Title</FormLabel>
            {item.type == "overview" ? (
              <Input value={"Overview"} isDisabled={true} />
            ) : (
              <Input
                ref={titleHandle}
                placeholder="Connect a Bank"
                value={contentList[index].title}
                onChange={(e) => handleContentChange(e, index, "title")}
              />
            )}
          </FormControl>
        </Stack>
        <FormControl id="body">
          <FormLabel>Body Text</FormLabel>
          <Textarea
            ref={bodyHandle}
            placeholder="Here is a sample placeholder"
            size="md"
            style={{ minHeight: 200 }}
            value={contentList[index].body}
            onChange={(e) => handleContentChange(e, index, "body")}
          />
        </FormControl>
      </Stack>
    </Box>
  );
};

export default StepCard;
