import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, View, Text, Image, ActivityIndicator } from "react-native";
import {
  Box,
  Button,
  Stack,
  StackDivider,
  Text as CText,
  Divider,
  HStack,
  useColorModeValue,
  Wrap,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Context as CompanyContext } from "@context/CompanyContext";
import StepCard from "./StepCard";
import ProspectCard from "./ProspectCard";
import PreloadCard from "./PreloadCard";
import { motion } from "framer-motion";
import * as RootNavigation from "../../RootNavigation";
import { toast } from "react-toastify";

const EditScreen = () => {
  const {
    editPlan,
    setEditingPlan,
    state: { company, templates, editingPlan },
  } = useContext(CompanyContext);
  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [contentList, setContentList] = useState(null);
  const [preload, setPreload] = useState(null);
  const [whitelist, setWhitelist] = useState(null);
  const [prospectContent, setProspectContent] = useState(null);
  const [isTeamEditing, setIsTeamEditing] = useState(false);
  const tagColor = useColorModeValue("gray.600", "gray.300");
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const boxShadowMode = useColorModeValue("sm", "sm-dark");

  useEffect(() => {
    if (editingPlan != null) {
      console.log(editingPlan);
      setCompanyName(editingPlan.prospectCompany);
      setContactName(editingPlan.prospectName);
      setContentList(editingPlan.content);
      if (editingPlan.prospectWhitelist != null)
        setWhitelist({ prospectWhitelist: editingPlan.prospectWhitelist });
      if (editingPlan.prospectContent != null)
        setProspectContent({ prospectContent: editingPlan.prospectContent });

      const selectedTemplate =
        editingPlan.template?.templateName != null
          ? editingPlan.template
          : templates.find(
              (temp) => `${temp._id}` == `${editingPlan.template}`
            );
      setSelectedTemplate(selectedTemplate);
      console.log(selectedTemplate);
      if (selectedTemplate.preload && selectedTemplate.preload.length > 0) {
        setPreload([
          ...selectedTemplate.preload.map((i) => {
            const fetchPreload = editingPlan.preload.find(
              (item) => `${item.fieldKey}` == `${i.fieldKey}`
            );

            return {
              fieldKey: i.fieldKey,
              componentType: i.componentType,
              value: fetchPreload?.value
                ? fetchPreload.value
                : i.value
                ? i.value
                : "",
            };
          }),
        ]);
      } else {
        setPreload(null);
      }
      if (editingPlan.metadata?.editingPermission != null) {
        const editingPermission = editingPlan.metadata?.editingPermission;
        if (editingPermission == "personal") setIsTeamEditing(false);
        if (editingPermission == "team") setIsTeamEditing(true);
      }
    } else {
      setSelectedTemplate(null);
      setContentList(null);
      setPreload(null);
    }
  }, [editingPlan]);

  const validateInputs = () => {
    if (companyName == "" || contactName == "") {
      toast.error("Company Name and Contact Name must be completed");
      return false;
    }
    return true;
  };

  const onCreate = async () => {
    if (isLoading == true) return;
    const isValidated = validateInputs();
    if (!isValidated) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    await editPlan({
      planId: editingPlan._id,
      prospectCompany: companyName,
      prospectName: contactName,
      prospectWhitelist:
        whitelist != null && whitelist.prospectWhitelist?.length > 0
          ? whitelist.prospectWhitelist
          : undefined,
      prospectContent:
        prospectContent != null ? prospectContent.prospectContent : undefined,
      content: contentList,
      preload,
      metadata: {
        ...editingPlan.metadata,
        editingPermission: isTeamEditing ? "team" : "personal",
      },
    });
    onOpen();
  };

  const handleContentChange = (e, index, field) => {
    const { name, value } = e.target;
    const list = [...contentList];
    list[index][field] = value;
    setContentList(list);
  };

  const handlePreloadChange = (e, index) => {
    const { name, value } = e.target;
    const preloadData = [...preload];
    preloadData[index].value = value;
    setPreload(preloadData);
  };

  const re = /\{\{.*?}}/g;
  const findVars = (str) => {
    const matches = str.match(re);
    if (matches == null) {
      return [];
    } else {
      return matches;
    }
  };

  console.log(selectedTemplate);

  if (!editingPlan)
    return (
      <View style={{ alignSelf: "center", marginTop: 50 }}>
        <ActivityIndicator />
      </View>
    );

  return (
    <View
      style={{
        paddingTop: 40,
        paddingHorizontal: 40,
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <Text style={styles.header}>Edit Existing Link</Text>

      <Box
        py={{
          base: "4",
          md: "8",
        }}
      >
        <Stack
          direction={{
            base: "column",
            lg: "row",
          }}
          spacing={{
            base: "5",
            lg: "8",
          }}
          justify="space-between"
        >
          <Box flexShrink={0} w="xs">
            <CText fontSize="lg" fontWeight="medium">
              Nuts & Bolts
            </CText>
            <View style={{ marginVertical: 5 }} />
            <CText color="muted" fontSize="sm">
              Basic info about the prospect you're pitching
            </CText>
          </Box>

          <Box
            as="form"
            bg="bg-surface"
            boxShadow={boxShadowMode}
            borderRadius="lg"
            w={{ lg: "3xl" }}
          >
            <Stack
              spacing="5"
              px={{
                base: "4",
                md: "6",
              }}
              py={{
                base: "5",
                md: "6",
              }}
            >
              <Stack
                spacing="6"
                direction={{
                  base: "column",
                  md: "row",
                }}
              >
                <FormControl id="companyName" isRequired={true}>
                  <FormLabel>Company Name</FormLabel>
                  <Input
                    placeholder="Dunder Mifflin"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </FormControl>
                <FormControl id="contactName" isRequired={true}>
                  <FormLabel>Company Contact Name</FormLabel>
                  <Input
                    placeholder="Michael Scott"
                    value={contactName}
                    onChange={(e) => setContactName(e.target.value)}
                  />
                </FormControl>
              </Stack>
              <FormControl id="templateSelect" isRequired={true}>
                <FormLabel>Template</FormLabel>
                <Input
                  value={
                    selectedTemplate != null
                      ? selectedTemplate.templateName
                      : ""
                  }
                  disabled={true}
                />
              </FormControl>
            </Stack>
          </Box>
        </Stack>

        <View style={{ marginVertical: 30 }} />

        {preload != null ? (
          <View style={{ marginBottom: 30 }}>
            <HStack>
              <Divider />
              <CText fontSize="lg" fontWeight="medium" whiteSpace="nowrap">
                Template Preload Items
              </CText>
              <Divider />
            </HStack>
            <View style={{ marginVertical: 30 }} />
            <Stack
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: "5",
                lg: "8",
              }}
              justify="space-between"
            >
              <Box flexShrink={0} w="xs">
                <View>
                  <CText fontSize="lg" fontWeight="medium">
                    What are Preload Items?
                  </CText>
                  <View style={{ marginVertical: 5 }} />
                  <CText color="muted" fontSize="sm">
                    These are custom options tied to the template selected to
                    further personalize every instance.
                  </CText>
                </View>
              </Box>

              <PreloadCard
                data={preload}
                schema={selectedTemplate?.preload}
                handlePreloadChange={handlePreloadChange}
                w={{
                  lg: "3xl",
                }}
                whitelist={whitelist}
                setWhitelist={setWhitelist}
                prospectContent={prospectContent}
                setProspectContent={setProspectContent}
                isTeamEditing={isTeamEditing}
                setIsTeamEditing={setIsTeamEditing}
              />
            </Stack>
          </View>
        ) : null}

        {contentList != null ? (
          <View>
            <HStack>
              <Divider />
              <CText fontSize="lg" fontWeight="medium" whiteSpace="nowrap">
                Proposal Content
              </CText>
              <Divider />
            </HStack>
            <View style={{ marginVertical: 30 }} />
            <Stack spacing="5" divider={<StackDivider />}>
              {contentList.map((item, index) => {
                const matchedVars = findVars(item.title + " " + item.body);

                return (
                  <Stack
                    key={`${item._id}`}
                    direction={{
                      base: "column",
                      lg: "row",
                    }}
                    spacing={{
                      base: "5",
                      lg: "8",
                    }}
                    justify="space-between"
                  >
                    <Box flexShrink={0} w="xs">
                      {item.type == "overview" ? (
                        <View>
                          <CText fontSize="lg" fontWeight="medium">
                            Overview
                          </CText>
                          <View style={{ marginVertical: 5 }} />
                          <CText color="muted" fontSize="sm">
                            This is the cover letter that is shown on the first
                            tab. This is typically a good place to write a quick
                            summary of what you about the customer and how you
                            can help.
                          </CText>
                        </View>
                      ) : (
                        <View>
                          <CText fontSize="lg" fontWeight="medium">
                            Step No. {item.goal.stepNumber}
                          </CText>
                          <CText fontSize="lg" fontWeight="medium">
                            Goal: {item.goal.title}
                          </CText>
                          <View style={{ marginVertical: 5 }} />
                          <CText color="muted" fontSize="sm">
                            Component type: {item.model.component}
                          </CText>
                          <View style={{ marginVertical: 10 }} />
                          {matchedVars.length > 0 ? (
                            <View
                              style={{
                                backgroundColor: "white",
                                padding: 10,
                                borderRadius: 10,
                              }}
                            >
                              <CText fontSize="md" fontWeight="medium">
                                Dynamic Variables
                              </CText>
                              <Wrap shouldWrapChildren mt="5" color={tagColor}>
                                {matchedVars.map((tag) => (
                                  <Tag key={tag} color="inherit" px="3">
                                    {tag}
                                  </Tag>
                                ))}
                              </Wrap>
                            </View>
                          ) : null}
                        </View>
                      )}
                    </Box>
                    <StepCard
                      item={item}
                      index={index}
                      contentList={contentList}
                      handleContentChange={handleContentChange}
                      w={{
                        lg: "3xl",
                      }}
                    />
                  </Stack>
                );
              })}
            </Stack>
            <View style={{ marginTop: 50, width: "100%" }}>
              <motion.div whileHover={{ scale: 1.02 }}>
                <Button
                  variant="primary"
                  bg="#4f41f1"
                  onClick={() => onCreate()}
                  isLoading={isLoading}
                  w="100%"
                >
                  Edit Link
                </Button>
              </motion.div>
            </View>
          </View>
        ) : null}
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsLoading(false);
          setEditingPlan(null);
          onClose();
          RootNavigation.navigate("Home");
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Link edited!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CText color="muted" fontSize="sm">
              Easy, right? Your plan was edited successfully at https://
              {company && company.domain ? company.domain : null}.coast.rest/
              {editingPlan._id}
            </CText>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme={"purple"}
              mr={3}
              onClick={() =>
                window.open(
                  `https://${
                    company && company.domain ? company.domain : null
                  }.coast.rest/${editingPlan._id}`,
                  "_blank"
                )
              }
            >
              Check it out
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: 30,
    fontWeight: "500",
  },
});

export default EditScreen;
