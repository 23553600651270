import * as React from "react";
import { Box, Button, Flex, useColorModeValue as mode } from "@chakra-ui/react";
import { NavBar } from "./components/Navigation/NavBar";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { View, ScrollView, Image, useWindowDimensions } from "react-native";
import { navigationRef } from "./RootNavigation";
import * as Linking from "expo-linking";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { enableScreens } from "react-native-screens";
import HomeScreen from "./screens/Home/HomeScreen";
import LoadingView from "./components/LoadingView";
import SignUpScreen from "./screens/Auth/SignUp/SignUpScreen";
import LoginScreen from "./screens/Auth/Login/LoginScreen";
import CreateScreen from "./screens/Create/CreateScreen";
import EditScreen from "./screens/Create/EditScreen";
import TrackScreen from "./screens/Track/TrackScreen";
import BuildScreen from "./screens/Build/BuildScreen";

import { Context as AuthContext } from "./context/AuthContext";
import { Context as UserContext } from "./context/UserContext";

import posthog from "posthog-js";
import WebFont from "webfontloader";

enableScreens();

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const prefix = Linking.createURL("/");

export default function AppNavigator() {
  const {
    tryLocalLogin,
    logout,
    state: { token },
  } = React.useContext(AuthContext);
  const {
    fetchUserInfo,
    resetUserInfo,
    state: { userInfo },
  } = React.useContext(UserContext);
  const [fontsLoaded, setFontsLoaded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadUpComplete, setLoadUpComplete] = React.useState(false);
  const [navChange, setNavChange] = React.useState(0);

  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        Home: "",
        Create: "create",
        Edit: "edit",
        Track: "track",
        Build: "build",
        SignUp: "signup/:companyId",
        Login: "login",
      },
    },
  };

  React.useEffect(() => {
    const handleAuth = async () => {
      try {
        setIsLoading(true);
        console.log("CHECKING TOKEN");
        await tryLocalLogin();
        setIsLoading(false);
      } catch (e) {
        console.log("error", e);
      }
    };
    handleAuth();
  }, [token]);

  const loadHome = async () => {
    console.log("----RUNNING LOADHOME");
    try {
      await fetchUserInfo();
    } catch (e) {
      console.log(e);
      await resetUserInfo();
      logout();
    }
  };

  React.useEffect(() => {
    const handleLoadUp = async () => {
      if (!isLoading) {
        // Not loading == handleAuth function has completed
        if (token != null) {
          // User is signed in -> run loadHome()
          await loadHome();
          setLoadUpComplete(true);
        } else {
          // User is not logged in
          console.log("RESETTING USER INFO");
          await resetUserInfo();
          setLoadUpComplete(true);

          posthog.capture("Dashboard Load Unauthorized");
        }
      }
    };
    handleLoadUp();
  }, [isLoading]);

  React.useEffect(() => {
    const identifyUser = async () => {
      if (userInfo != null) {
        // await posthog.group("company", `${userInfo.company._id}`, {
        //   company_name: userInfo.company.name,
        // });
        // await posthog.identify(userInfo._id, {
        //   firstName: userInfo.firstName,
        //   lastName: userInfo.lastName,
        //   email: userInfo.email,
        //   role: userInfo.role,
        //   plansCreated: userInfo.plansCreated,
        // });

        posthog.capture("Dashboard Load Success");
      }
    };
    identifyUser();
  }, [userInfo]);

  React.useEffect(() => {
    WebFont.load({
      google: {
        //families: [plan.company.fontImport],
        families: ["Space Grotesk:300,400,800"],
      },
      active: () => {
        console.log("Fonts loaded :)");
        setFontsLoaded(true);
      },
    });
  }, []);

  // React.useEffect(() => {
  //   console.log("running");
  //   if (document.getElementById("highlight-form-container") != null) {
  //     console.log("button present");
  //     document.getElementById("highlight-form-submit-button").onclick =
  //       function () {
  //         toast(
  //           "Got it! Our team monitors all submissions and will be in touch."
  //         );
  //       };
  //   }
  // }, []);

  if (
    isLoading ||
    !fontsLoaded ||
    !loadUpComplete ||
    (token && !userInfo)
    // !companyInfo ||
  ) {
    return <LoadingView />;
  } else {
    return (
      <NavigationContainer
        ref={navigationRef}
        linking={linking}
        onStateChange={() => setNavChange(navChange + 1)}
        onReady={() => setNavChange(navChange + 1)}
      >
        {token != null ? (
          <Box height="100vh" overflow="hidden" position="relative">
            <View
              style={{
                width: "100%",
                height: 55,
                backgroundColor: "white",
                borderBottomColor: "#f0f0f0",
                borderBottomWidth: 1,
                paddingHorizontal: "1rem",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Image
                source={require("../assets/Coast-Logo.svg")}
                style={{
                  width: 75,
                  resizeMode: "contain",
                  height: 25,
                }}
              />
              <Button size="sm" onClick={() => logout()}>
                Log Out
              </Button>
            </View>
            <Flex h={"calc(100vh - 55px)"} id="app-container">
              <NavBar navChange={navChange} />
              <Box flexGrow={"1"}>
                <View style={{ height: "100%", width: "100%" }}>
                  <Stack.Navigator headerShown="none">
                    <Stack.Screen
                      name="Home"
                      component={HomeScreen}
                      options={({ route, navigation }) => ({
                        headerShown: false,
                        title: "Coast",
                        cardStyle: {
                          backgroundColor: "#f9f9f9",
                        },
                      })}
                    />
                    <Stack.Screen
                      name="Create"
                      component={CreateScreen}
                      options={({ route, navigation }) => ({
                        headerShown: false,
                        title: "Coast | Create",
                        cardStyle: {
                          backgroundColor: "#f9f9f9",
                        },
                      })}
                    />
                    <Stack.Screen
                      name="Edit"
                      component={EditScreen}
                      options={({ route, navigation }) => ({
                        headerShown: false,
                        title: "Coast | Edit",
                        cardStyle: {
                          backgroundColor: "#f9f9f9",
                        },
                      })}
                    />
                    <Stack.Screen
                      name="Track"
                      component={TrackScreen}
                      options={({ route, navigation }) => ({
                        headerShown: false,
                        title: "Coast | Track",
                        cardStyle: {
                          backgroundColor: "#f9f9f9",
                        },
                      })}
                    />
                    <Stack.Screen
                      name="Build"
                      component={BuildScreen}
                      options={({ route, navigation }) => ({
                        headerShown: false,
                        title: "Coast | Build",
                        cardStyle: {
                          backgroundColor: "#f9f9f9",
                        },
                      })}
                    />
                  </Stack.Navigator>
                </View>
              </Box>
            </Flex>
          </Box>
        ) : (
          <Stack.Navigator headerShown="none">
            <Stack.Screen
              name="Login"
              component={LoginScreen}
              options={({ route, navigation }) => ({
                headerShown: false,
                title: "Coast",
                cardStyle: {},
              })}
            />
            <Stack.Screen
              name="SignUp"
              component={SignUpScreen}
              options={({ route, navigation }) => ({
                headerShown: false,
                title: "Coast",
                cardStyle: {},
              })}
            />
          </Stack.Navigator>
        )}
      </NavigationContainer>
    );
  }
}
