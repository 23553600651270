import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { AccountSwitcherButton } from "./AccountSwitcherButton";
import { Context as AuthContext } from "@context/AuthContext";
import { Context as UserContext } from "@context/UserContext";
import { H } from "highlight.run";

export const AccountSwitcher = () => {
  const { logout } = useContext(AuthContext);
  const {
    state: { userInfo },
  } = useContext(UserContext);

  return (
    <Menu>
      <AccountSwitcherButton />
      <MenuList
        shadow="lg"
        py="4"
        color={useColorModeValue("gray.600", "gray.200")}
        px="3"
      >
        <Text fontWeight="medium" mb="2">
          {userInfo ? userInfo.email : "Email Loading"}
        </Text>
        {/* <MenuOptionGroup defaultValue="chakra-ui">
          <MenuItemOption value="chakra-ui" fontWeight="semibold" rounded="md">
            Chakra UI
          </MenuItemOption>
          <MenuItemOption value="careerlyft" fontWeight="semibold" rounded="md">
            CareerLyft
          </MenuItemOption>
        </MenuOptionGroup>
        <MenuDivider />
        <MenuItem rounded="md">Workspace settings</MenuItem>
        <MenuItem rounded="md">Add an account</MenuItem> */}
        <MenuDivider />
        <MenuItem rounded="md" onClick={() => H.toggleSessionFeedbackModal()}>
          Contact Support
        </MenuItem>
        <MenuItem rounded="md" onClick={() => logout()}>
          Log Out
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
