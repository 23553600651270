import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import { Context as CompanyContext } from "@context/CompanyContext";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  useColorModeValue,
  Badge,
  Switch,
} from "@chakra-ui/react";
import Form from "@rjsf/chakra-ui";

const pickForegroundText = (bgColor) => {
  const lightColor = "#FFFFFF";
  const darkColor = "#000000";
  var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

const PreloadCard = (props) => {
  const {
    data,
    schema,
    handlePreloadChange,
    whitelist,
    setWhitelist,
    prospectContent,
    setProspectContent,
    isTeamEditing,
    setIsTeamEditing,
  } = props;
  const {
    state: { company },
  } = useContext(CompanyContext);

  return (
    <View>
      <Box
        bg="bg-surface"
        boxShadow={useColorModeValue("sm", "sm-dark")}
        borderRadius="lg"
        w={props.w}
      >
        <Stack
          spacing="5"
          px={{
            base: "4",
            md: "6",
          }}
          py={{
            base: "5",
            md: "6",
          }}
        >
          <Badge style={{ alignSelf: "flex-start" }} colorScheme="red">
            REQUIRED FIELDS
          </Badge>
          <View style={{ marginVertical: 5 }} />
          {data.map((item, index) => {
            if (schema[index]?.value == null) {
              if (item.componentType == "color") {
                const logoField = data.find((i) =>
                  i.fieldKey?.toLowerCase().includes("logo")
                );

                return (
                  <View key={item.fieldKey} style={{ marginVertical: 10 }}>
                    <Form
                      schema={{
                        type: "string",
                        title: schema[index]?.label,
                        default: "#151ce6",
                      }}
                      formData={item.value}
                      uiSchema={{
                        "ui:submitButtonOptions": {
                          props: {
                            disabled: false,
                            className: "btn btn-info",
                          },
                          norender: true,
                          submitText: "Submit",
                        },
                        "ui:widget": "color",
                      }}
                      onChange={(e) => {
                        handlePreloadChange(
                          { target: { value: e.formData } },
                          index
                        );
                      }}
                    />
                    {item.value == null || item.value == "" ? (
                      <Text style={{ fontSize: 10, color: "red" }}>
                        No value set
                      </Text>
                    ) : (
                      <Text style={{ fontSize: 10, color: "grey" }}>
                        {item.value}
                      </Text>
                    )}

                    {schema[index]?.label?.toLowerCase().includes("credit") &&
                    item.value != null &&
                    item.value != "" ? (
                      <View>
                        <View
                          style={{
                            alignSelf: "flex-end",
                            paddingHorizontal: "15px",
                            paddingVertical: "15px",
                            height: "150px",
                            width: "250px",
                            justifyContent: "space-between",
                            backgroundColor: item.value,
                            borderRadius: "10px",
                          }}
                        >
                          <Image
                            source={{
                              uri: logoField?.value,
                            }}
                            style={{
                              width: "70px",
                              height: "35px",
                              alignSelf: "flex-start",
                            }}
                            resizeMode="contain"
                          />
                          <Text
                            style={{
                              fontSize: 15,
                              color: pickForegroundText(item.value),
                            }}
                          >
                            6037 9975 9598 3090
                          </Text>
                          <Image
                            source={{
                              uri: "https://www.pngall.com/wp-content/uploads/2017/05/Visa-Logo-PNG-Image.png",
                            }}
                            style={{
                              height: "20px",
                              width: "50px",
                              alignSelf: "flex-end",
                            }}
                            resizeMode="contain"
                          />

                          <Text
                            style={{
                              fontSize: 10,
                              color: pickForegroundText(item.value),
                              position: "absolute",
                              bottom: "10px",
                              left: "15px",
                            }}
                          >
                            Exp 08/24
                          </Text>
                        </View>
                        <Text style={{ marginTop: 20, fontSize: 14 }}>
                          <Text style={{ fontWeight: "bold" }}>Note: </Text>{" "}
                          This is a preview rendering of a credit card with the
                          selected color. It will not appear identical to the
                          embedded credit card.
                        </Text>
                      </View>
                    ) : null}
                  </View>
                );
              } else if (item.componentType != "json") {
                return (
                  <View key={item.fieldKey} style={{ marginVertical: 10 }}>
                    <FormControl>
                      <FormLabel>{schema[index]?.label}</FormLabel>
                      <Input
                        placeholder=""
                        value={item.value}
                        onChange={(e) => handlePreloadChange(e, index)}
                      />
                    </FormControl>
                    {schema[index]?.label?.toLowerCase().includes("logo") &&
                    item.value != null &&
                    item.value != "" ? (
                      <View style={{ alignSelf: "flex-end" }}>
                        <Image
                          source={{ uri: item.value }}
                          style={{
                            width: 200,
                            height: 200,
                          }}
                          resizeMode="contain"
                        />
                        <Text style={{ fontSize: 10, color: "grey" }}>
                          If no image appears above, please try another link.
                        </Text>
                      </View>
                    ) : null}
                  </View>
                );
              }
            }
          })}
        </Stack>
      </Box>
      <View style={{ marginVertical: 20 }} />
      <Box
        bg="bg-surface"
        boxShadow={useColorModeValue("sm", "sm-dark")}
        borderRadius="lg"
        w={props.w}
      >
        <Stack
          spacing="5"
          px={{
            base: "4",
            md: "6",
          }}
          py={{
            base: "5",
            md: "6",
          }}
        >
          <Badge style={{ alignSelf: "flex-start" }} colorScheme="green">
            OPTIONAL FIELDS
          </Badge>
          <View style={{ marginVertical: 5 }} />
          <View>
            <Form
              schema={{
                type: "object",
                properties: {
                  prospectContent: {
                    type: "object",
                    title: "",

                    properties: {
                      apiKey: {
                        type: "string",
                        title: "Prospect API Key",
                      },
                      banner: {
                        type: "string",
                        title: "Text to Display on Banner",
                      },
                    },
                  },
                },
              }}
              formData={prospectContent}
              uiSchema={{
                "ui:submitButtonOptions": {
                  props: {
                    disabled: false,
                    className: "btn btn-info",
                  },
                  norender: true,
                  submitText: "Submit",
                },
                prospectContent: {
                  banner: {
                    "ui:placeholder": `{plan.prospectCompany}'s pre-integrated ${
                      company != null ? company.name : null
                    } environment - Execute real requests to see the API in action for your use case`,
                  },
                },
              }}
              onChange={(e) => {
                setProspectContent(e.formData);
              }}
            />
          </View>
          <View style={{ marginTop: -10 }}>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="team-editing" mb="0">
                Allow team editing?
              </FormLabel>
              <Switch
                id="team-editing"
                size="lg"
                isChecked={isTeamEditing}
                onChange={(e) => setIsTeamEditing(e.target.checked)}
              />
            </FormControl>
          </View>
          <View>
            <FormLabel>Link Access Control</FormLabel>
            <Text style={{ fontWeight: "bold" }}>
              *This option will restrict link access to only the emails you
              enter. Leave this blank to make the link available to everyone.*
            </Text>
            <Text style={{ marginTop: 10, color: "grey", fontSize: 11 }}>
              (Example: If you enter john@demo_company.com, only John's email
              will be given access)
            </Text>
            <Form
              schema={{
                type: "object",
                properties: {
                  prospectWhitelist: {
                    type: "array",
                    title: "",
                    items: {
                      type: "object",
                      properties: {
                        email: {
                          type: "string",
                          title: "Email Address",
                        },
                        visitMax: {
                          type: "number",
                          title: "Maximum Number of Visits",
                        },
                      },
                    },
                  },
                },
              }}
              formData={whitelist}
              uiSchema={{
                "ui:submitButtonOptions": {
                  props: {
                    disabled: false,
                    className: "btn btn-info",
                  },
                  norender: true,
                  submitText: "Submit",
                },
              }}
              onChange={(e) => {
                setWhitelist(e.formData);
              }}
            />
          </View>
        </Stack>
      </Box>
    </View>
  );
};

export default PreloadCard;
