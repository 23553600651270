import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, View, Text, Image, ActivityIndicator } from "react-native";
import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  useBreakpointValue,
  Avatar,
  Badge,
  HStack,
  useColorModeValue as mode,
  Text as CText,
  Skeleton,
} from "@chakra-ui/react";
import { Context as UserContext } from "@context/UserContext";
import { Context as CompanyContext } from "@context/CompanyContext";
import StatGroup from "@components/Stats/StatGroup";
import ProposalTable from "@components/ProposalTable/ProposalTable";
import * as RootNavigation from "../../RootNavigation";
import { FiMail, FiSend, FiUsers } from "react-icons/fi";
import posthog from "posthog-js";

const calculateAvgPageViews = (lst) => {
  let sum = 0;
  let divisor = 0;

  for (let i = 0; i < lst.length; i++) {
    const currItem = lst[i];
    if (currItem.stats.totalPageViews != null) {
      sum += currItem.stats.totalPageViews;
      divisor += 1;
    }
  }

  if (divisor == 0) {
    return "N/A";
  } else {
    return Math.round((sum / divisor) * 100) / 100;
  }
};

const calculateAvgUniqueViewers = (lst) => {
  let sum = 0;
  let divisor = 0;

  for (let i = 0; i < lst.length; i++) {
    const currItem = lst[i];
    if (currItem.stats.uniqueViewers != null) {
      sum += currItem.stats.uniqueViewers;
      divisor += 1;
    }
  }

  if (divisor == 0) {
    return "N/A";
  } else {
    return Math.round((sum / divisor) * 100) / 100;
  }
};

const HomeScreen = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const {
    fetchDocumentation,
    fetchPlans,
    state: { company, plans, ownerPlans, isPlansRefreshed },
  } = useContext(CompanyContext);

  useEffect(() => {
    if (plans == null) {
      fetchDocumentation();
    }
  }, []);

  useEffect(() => {
    if (company != null && !isPlansRefreshed) {
      fetchPlans();
    }
  }, [company]);

  const stats =
    ownerPlans != null
      ? [
          {
            icon: FiUsers,
            label: "Total Proposals Created",
            value: ownerPlans.length,
            delta: {
              value: "5",
              isUpwardsTrend: true,
            },
          },
          {
            icon: FiMail,
            label: "Avg. Page Views Per Proposal",
            value: calculateAvgPageViews(ownerPlans),
            delta: {
              value: "2.3%",
              isUpwardsTrend: true,
            },
          },
          {
            icon: FiSend,
            label: "Avg. Unique Viewers Per Proposal",
            value: calculateAvgUniqueViewers(ownerPlans),
            delta: {
              value: "0.1%",
              isUpwardsTrend: false,
            },
          },
        ]
      : [];

  return (
    <View
      style={{
        paddingTop: 40,
        paddingHorizontal: 40,
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <Text style={styles.header}>Hey, {userInfo.firstName}</Text>

      {ownerPlans == null ? (
        <View
          style={{
            marginVertical: 20,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Skeleton height="125px" width={"32%"} />
          <Skeleton height="125px" width={"32%"} />
          <Skeleton height="125px" width={"32%"} />
        </View>
      ) : (
        <StatGroup stats={stats} />
      )}
      <Box
        as="section"
        bg="bg-surface"
        pt={{ base: "4", md: "8" }}
        pb={{ base: "12", md: "24" }}
      >
        <Container>
          <Stack
            spacing="4"
            direction={{ base: "column", md: "row" }}
            justify="space-between"
          >
            <Stack spacing="1">
              <Heading
                size={useBreakpointValue({ base: "xs", md: "sm" })}
                fontWeight="medium"
              >
                Your History
              </Heading>
              <CText color="muted">Log of created proposals</CText>
            </Stack>
            <Stack spacing="3">
              <Button
                variant="primary"
                bg="#4f41f1"
                onClick={() => RootNavigation.navigate("Create")}
              >
                Create New Proposal
              </Button>
              {!isPlansRefreshed ? (
                <View
                  style={{
                    marginTop: 20,
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <ActivityIndicator color="grey" style={{ marginRight: 10 }} />
                  <CText fontSize={"smaller"} color="muted">
                    Refreshing analytics
                  </CText>
                </View>
              ) : (
                <CText
                  fontSize={"smaller"}
                  style={{ marginTop: 20, textAlign: "flex-end" }}
                  color="muted"
                >
                  Analytics update every hour
                </CText>
              )}
            </Stack>
          </Stack>

          <View style={{ marginVertical: 20 }} />

          {ownerPlans == null ? (
            <ActivityIndicator style={{ alignSelf: "center" }} />
          ) : ownerPlans.length == 0 ? (
            <CText color="muted">No proposals found</CText>
          ) : (
            <ProposalTable data={ownerPlans} />
          )}
        </Container>
      </Box>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: 30,
    fontWeight: "500",
  },
});

export default HomeScreen;
