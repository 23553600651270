import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Context as ConstructContext } from "@context/ConstructContext";
import { Context as CompanyContext } from "@context/CompanyContext";
import {
  Box,
  Button,
  Stack,
  Text as CText,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { FiArrowUp } from "react-icons/fi";
import ContentOverview from "./ContentOverview";
import OverviewDetails from "./OverviewDetails";
import StepOrder from "./StepOrder";
import StepDetails from "./StepDetails";
import ComponentDetails from "./ComponentDetails";
import StepSchema from "./StepSchema";
import _ from "lodash";

const parsePlan = (planContent) => {
  const organizedGoals = [];
  let currGoal = null;

  planContent.forEach((item, index) => {
    if (item.type == "step") {
      if (currGoal == null) {
        currGoal = {
          title: item.goal.title,
          steps: [{ title: item.title, navIndex: index, isValid: true }],
        };
      } else if (currGoal.title == item.goal.title) {
        currGoal.steps.push({
          title: item.title,
          navIndex: index,
          isValid: true,
        });
      } else if (currGoal.title != item.goal.title) {
        organizedGoals.push(currGoal);
        currGoal = {
          title: item.goal.title,
          steps: [{ title: item.title, navIndex: index, isValid: true }],
        };
      }

      if (currGoal != null && index == planContent.length - 1)
        organizedGoals.push(currGoal);
    }
  });

  return organizedGoals;
};

const BuildScreen = () => {
  const {
    fetchSchemas,
    createTemplate,
    addContent,
    modContent,
    removeContent,
    setGoalList,
    setTemplateName,
    setExistingTemplate,
    setUpdateTemplate,
    clearTemplate,
    state: { bankSchemas, content, navIndex, templateName },
  } = useContext(ConstructContext);
  const {
    state: { templates },
  } = useContext(CompanyContext);

  useEffect(() => {
    if (!bankSchemas) {
      fetchSchemas();
    }
  }, []);

  useEffect(() => {
    if (content != null) {
      setGoalList({ lst: parsePlan(content) });
    }
  }, [content]);

  return (
    <View
      style={{
        paddingTop: 40,
        paddingHorizontal: 40,
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text style={styles.header}>Build a Template</Text>
        <View style={{ alignSelf: "flex-end", flexDirection: "row" }}>
          <View>
            <FormLabel>Update a Template</FormLabel>
            <Select
              placeholder="Select one"
              onChange={(e) => {
                if (e.target.value == null || e.target.value == "") {
                } else {
                  const selectedTemplate = templates.find(
                    (i) => `${i._id}` == `${e.target.value}`
                  );
                  setUpdateTemplate(selectedTemplate);
                }
              }}
            >
              {templates.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.templateName}
                </option>
              ))}
            </Select>
          </View>
          <View style={{ marginHorizontal: 10 }} />
          <View>
            <FormLabel>Build from a Template</FormLabel>
            <Select
              placeholder="Select one"
              onChange={(e) => {
                if (e.target.value == null || e.target.value == "") {
                } else {
                  const selectedTemplate = templates.find(
                    (i) => `${i._id}` == `${e.target.value}`
                  );
                  setExistingTemplate(selectedTemplate);
                }
              }}
            >
              {templates.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.templateName}
                </option>
              ))}
            </Select>
          </View>
          <View style={{ marginHorizontal: 25 }} />
          <View>
            <Button onClick={() => clearTemplate()}>Clear</Button>
          </View>
        </View>
      </View>
      <Box
        py={{
          base: "4",
          md: "8",
        }}
      >
        <Stack
          direction={{
            base: "column",
            lg: "row",
          }}
          spacing={{
            base: "5",
            lg: "8",
          }}
          justify="space-between"
        >
          <Box w={{ lg: "3xl" }}>
            <View style={{ alignSelf: "flex-start", maxWidth: 350 }}>
              <FormControl>
                <Input
                  id="large"
                  size="lg"
                  placeholder=" "
                  data-peer
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                />
                <FormLabel htmlFor="large" variant="floating" size="lg">
                  Template Name
                </FormLabel>
              </FormControl>
            </View>

            {content[navIndex] && content[navIndex].type == "overview" && (
              <View style={{ marginTop: 25 }}>
                <OverviewDetails />
              </View>
            )}
            {content[navIndex] && content[navIndex].type == "step" && (
              <View>
                <StepOrder />
                <StepDetails />
                {content[navIndex].models != null ? (
                  content[navIndex].models.map((item, index) => (
                    <View style={{ marginVertical: 50 }}>
                      <ComponentDetails modelIndex={index} />
                      <StepSchema model={item} modelIndex={index} />
                    </View>
                  ))
                ) : content[navIndex].model != null ? (
                  <View style={{ marginVertical: 50 }}>
                    <ComponentDetails modelIndex={null} />
                    <StepSchema model={content[navIndex].model} />
                  </View>
                ) : null}
                <ComponentDetails
                  modelIndex={
                    content[navIndex].models != null
                      ? content[navIndex].models.length
                      : content[navIndex].model != null
                      ? 0
                      : null
                  }
                />
              </View>
            )}
          </Box>

          <Box flexShrink={0} w="xs">
            <ContentOverview templateName={templateName} />
            <TouchableOpacity
              style={{
                position: "fixed",

                bottom: 0,
                backgroundColor: "#4f41f1",
                paddingVertical: 12,
                paddingHorizontal: 25,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                flexDirection: "row",
              }}
            >
              <CText color={"white"}>State Visualizer</CText>
              <View style={{ marginHorizontal: 5 }} />
              <FiArrowUp color="white" fontSize="1.25rem" />
            </TouchableOpacity>
          </Box>
        </Stack>
      </Box>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: 30,
    fontWeight: "500",
    marginBottom: 20,
  },
});

export default BuildScreen;
