import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { View, Image, TouchableOpacity } from "react-native";
import { Context as AuthContext } from "@context/AuthContext";
import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { H } from "highlight.run";
import * as RootNavigation from "../../../RootNavigation";

import { PasswordField } from "./PasswordField";

const LoginScreen = () => {
  const {
    login,
    clearErrorMessage,
    state: { errorMessage },
  } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (errorMessage != "") {
      setIsLoading(false);
      clearErrorMessage();
    }
  }, [errorMessage]);

  const onSubmit = async () => {
    if (isLoading == true) return;
    setIsLoading(true);
    await login({
      email,
      password,
    });
  };

  return (
    <Container
      maxW="lg"
      py={{
        base: "12",
        md: "12",
      }}
      px={{
        base: "0",
        sm: "8",
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Image
            source={require("../../../../assets/Coast-Logo.svg")}
            style={{
              width: 125,
              resizeMode: "contain",
              height: 30,
              alignSelf: "center",
            }}
          />
          <View style={{ marginVertical: 10 }} />
          <Stack
            spacing={{
              base: "2",
              md: "3",
            }}
            textAlign="center"
          >
            <Heading
              size={useBreakpointValue({
                base: "xs",
                md: "sm",
              })}
            >
              Log in to your account
            </Heading>
            <HStack spacing="1" justify="center">
              <Text color="muted">Don't have an account?</Text>
              <Button
                variant="link"
                colorScheme="blue"
                onClick={() => RootNavigation.navigate("SignUp")}
              >
                Sign up
              </Button>
            </HStack>
          </Stack>
        </Stack>
        <Box
          py={{
            base: "0",
            sm: "8",
          }}
          px={{
            base: "4",
            sm: "10",
          }}
          bg={useBreakpointValue({
            base: "transparent",
            sm: "bg-surface",
          })}
          boxShadow={{
            base: "none",
            sm: useColorModeValue("md", "md-dark"),
          }}
          borderRadius={{
            base: "none",
            sm: "xl",
          }}
        >
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </FormControl>
              <PasswordField
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Stack>
            <HStack justify="space-between">
              <Checkbox defaultChecked>Remember me</Checkbox>
              {/* <Button variant="link" colorScheme="blue" size="sm">
                Forgot password?
              </Button> */}
            </HStack>
            <Stack spacing="6">
              <Button
                variant="primary"
                onClick={() => onSubmit()}
                isLoading={isLoading}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
        <TouchableOpacity onPress={() => H.toggleSessionFeedbackModal()}>
          <Text fontSize="xs" color="muted">
            Click here or contact support@trycoast.com for help
          </Text>
        </TouchableOpacity>
      </Stack>
    </Container>
  );
};

export default LoginScreen;
